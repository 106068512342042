import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import GoogleSignUpModal from "./googleSignUpModal";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OpeArt from "./opeArtNLogo";

export default function SignUpCard({ handleSignUpEmail, handleSignUpGoogle }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [successBanner, setSuccessBanner] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div>
            <Card className="signUpCard">
                <CardContent className="bottomSignCard">
                    <OpeArt></OpeArt>
                    <br></br>

                    <h3 className="signUpText">Sign Up</h3>

                    <div className="signUpEmailZipDiv">
                        <OutlinedInput
                            className="formFieldSignIn formFieldSignUpEmail"
                            value={email}
                            placeholder="Email"
                            autoComplete="current-email"
                            name="email"
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                        />
                        <OutlinedInput
                            className="formFieldSignIn formFieldSignUpZipCode"
                            value={zipCode}
                            id="zipCode"
                            placeholder="Zip Code"
                            name="zipCode"
                            autoComplete="postal-code"
                            onChange={(event) => {
                                setZipCode(event.target.value);
                            }}
                        />
                    </div>
                    <div className="signUpPasswordDiv">
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            className="inputTextField"
                            value={password}
                            placeholder="Password"
                            name="password"
                            onChange={(event) => {
                                setPassword(event.target.value);
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <i class="fas fa-eye-slash"></i>
                                        ) : (
                                            <i class="fas fa-eye"></i>
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showConfirmPassword ? "text" : "password"}
                            className="inputTextField"
                            value={confirmPassword}
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            onChange={(event) => {
                                setConfirmPassword(event.target.value);
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={
                                            handleMouseDownConfirmPassword
                                        }
                                        edge="end"
                                    >
                                        {showConfirmPassword ? (
                                            <i class="fas fa-eye-slash"></i>
                                        ) : (
                                            <i class="fas fa-eye"></i>
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </div>
                    <h3>{successBanner}</h3>
                    <h3>{passwordConfirmation}</h3>

                    <div className="signInButtonDiv">
                        <Button
                            className="signInButton"
                            size="small"
                            color="primary"
                            id="emailButton"
                            onClick={() => {
                                if (
                                    confirmPassword === password &&
                                    password.length > 0
                                ) {
                                    if (zipCode.length === 5) {
                                        handleSignUpEmail(
                                            email,
                                            password,
                                            zipCode
                                        );
                                    } else {
                                        setPasswordConfirmation(
                                            "A zip code is required"
                                        );
                                    }
                                } else {
                                    setPasswordConfirmation(
                                        "Your passwords do not match"
                                    );
                                }
                            }}
                        >
                            Sign Up
                        </Button>
                        {/* <Button
                            className="signInButton"
                            size="small"
                            color="primary"
                            id="facebookButton"
                            onClick={props.signUpFacebook}
                        >
                            Facebook
                        </Button> */}

                        {/* <Button
                            className="signInButtonGoogle"
                            size="small"
                            id="googleButton"
                            onClick={
                                () => handleOpen()
                                //open modal for zipcode
                                // props.signUpGoogle
                            }
                        >
                            Continue with Google
                        </Button> */}
                    </div>
                </CardContent>
            </Card>

            <GoogleSignUpModal
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleSignUpGoogle={handleSignUpGoogle}
            ></GoogleSignUpModal>
        </div>
    );
}

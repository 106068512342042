// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/firestore";
import "firebase/analytics";

const isProd = process.env.REACT_APP_ENVIRONMENT === "prod";
console.log("isProd", process.env.REACT_APP_ENVIRONMENT);
// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyAV7mgyGeFTIrXrkzo8quV7Fi9Sq59oMs4",
    authDomain: "mobilemogulneighbored.firebaseapp.com",
    databaseURL: "https://mobilemogulneighbored.firebaseio.com",
    projectId: "mobilemogulneighbored",
    storageBucket: "mobilemogulneighbored.appspot.com",
    messagingSenderId: "807726030838",
    appId: "1:807726030838:web:deb5b016e2885f73eb31d7",
    measurementId: "G-YCN5PM4GQM"
};

const firebaseConfigDev = {
    apiKey: "AIzaSyDmXvFq1R4d7T5ufkokoUXw0AYUiYvDGIs",
    authDomain: "neighbored-dev-14823.firebaseapp.com",
    projectId: "neighbored-dev-14823",
    storageBucket: "neighbored-dev-14823.appspot.com",
    messagingSenderId: "223100053314",
    appId: "1:223100053314:web:3b9618ad1974b0478d0adb",
    measurementId: "${config.measurementId}"
};

// Initialize Firebase
export const fire = firebase.initializeApp(
    isProd ? firebaseConfig : firebaseConfigDev
);
export const analytics = firebase.analytics();
const db = firebase.firestore();

export default db;

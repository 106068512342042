import React, { useEffect } from "react";
import PartyCard from "./partyCard";
import NoCitySignedUpCard from "./noCitySignedUpCard";
import NoEventsInCityCard from "./noEventsInCityCard";
import { DateSorter } from "../TwoZeroFunctions/dateSorter";

export default function CityPartyCardList(props) {
    useEffect(() => {
        console.log("CITYPAGECITY", props.cityPageCity);
    });
    const filteredPartyList = props.partyList
        .filter((party) => {
            if (party.cityName === props.cityPageCity.name) {
                return party;
            }
        })
        .filter((party) => {
            if (party.approved) {
                return party;
            }
        })
        .filter((party) => {
            let isHidden = props.checkHiddenIncludes(party);
            if (!isHidden) {
                return party;
            }
        });

    const sortedFilteredPartyList = DateSorter(filteredPartyList);
    console.log("sortedFilteredPartyList", sortedFilteredPartyList);
    return (
        <div>
            {props.cityPageCity.isSignedUp ? (
                sortedFilteredPartyList.length > 0 ? (
                    sortedFilteredPartyList.map((party) => (
                        <PartyCard
                            party={party}
                            key={party.uid}
                            checkFavoritesIncludes={
                                props.checkFavoritesIncludes
                            }
                            checkHiddenIncludes={props.checkHiddenIncludes}
                            userSettingsDoc={props.userSettingsDoc}
                            page={props.page}
                            handlePartyCardCityButton={
                                props.handlePartyCardCityButton
                            }
                            handleFavoriteClick={props.handleFavoriteClick}
                            handleHiddenClick={props.handleHiddenClick}
                        />
                    ))
                ) : (
                    <NoEventsInCityCard></NoEventsInCityCard>
                )
            ) : (
                <NoCitySignedUpCard></NoCitySignedUpCard>
            )}
        </div>
    );
}

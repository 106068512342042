import latLongCalulations from "./latLongCalculations";
import cities from "../constants/cities";

export default function FillQueryList(lat, long) {
    let radius = 10000;

    let filteredCities = cities.filter((city) => {
        let distance = latLongCalulations(
            city.latitude,
            city.longitude,
            lat,
            long
        );
        return distance < radius;
    });

    filteredCities.sort((a, b) => {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    });

    return filteredCities;
}

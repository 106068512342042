import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "97.5%"
        }
    }
}));

export default function BasicTextFields(props) {
    const classes = useStyles();

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <TextField
                id={props.id}
                label={props.label}
                type={props.type}
                autoComplete={props.autoComplete}
                variant="outlined"
                onChange={props.onChange}
                name={props.name}
                value={props.value}
                className={props.className}
            />
        </form>
    );
}

import cities from "../constants/cities";
import latLongCalculations from "./latLongCalculations";
const returnCityQueryNames = (lat, long) => {
    const radius = 10000;
    return cities.map((city) => {
        var citiesInRadius = [];
        let distance = latLongCalculations(
            lat,
            long,
            city.latitude,
            city.longitude
        );

        if (distance <= radius) {
            citiesInRadius = [...citiesInRadius, city];
        }

        return citiesInRadius;
    });
};
export default returnCityQueryNames;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import NeighboredCircle from "../images/NeighboredLogoV2.png";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    outerDiv: {
        backgroundColor: "white",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    tinyLogo: {
        width: "4rem",
        height: "4rem",
        margin: ".5rem"
    }
}));

export default function SmartBanner() {
    const iosLink = "https://apps.apple.com/us/app/neighbored/id1545220147";
    const androidLink =
        "https://play.google.com/store/apps/details?id=com.neighboredllc.neighbored";
    const classes = useStyles();

    const [isIos, setIsIos] = useState(
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    );
    return (
        <div className={classes.outerDiv}>
            <CardMedia
                component="img"
                alt="Neighbored Logo"
                className={classes.tinyLogo}
                image={NeighboredCircle}
                title="Neighbored Logo"
            />
            <div
                style={{
                    flex: 2,
                    textAlign: "left",
                    lineHeight: ".5rem",
                    margin: 0
                }}
            >
                <h3 style={{ marginBottom: ".75rem", marginTop: "0rem" }}>
                    Neighbored
                </h3>
                <p style={{ fontSize: ".66rem", margin: 0 }}>
                    By Neighbored LLC
                </p>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: ".2rem"
                    }}
                >
                    <p style={{ fontSize: ".5rem", margin: "0rem" }}>
                        Made with
                    </p>
                    <i
                        style={{
                            color: "#555",
                            fontSize: ".5rem",
                            marginLeft: ".25rem",
                            marginRight: ".25rem"
                        }}
                        class="fas fa-heart"
                    ></i>
                    <p style={{ fontSize: ".5rem", margin: 0 }}>from Iowa</p>
                </div>
            </div>
            <Button
                style={{
                    color: "white",
                    fontSize: "10px",
                    padding: ".1rem",
                    textTransform: "none",
                    margin: "1rem",
                    borderRadius: "40px"
                }}
                size="small"
                color="primary"
                onClick={() => {
                    console.log("open app store");
                    window.open(isIos ? iosLink : androidLink);
                }}
            >
                View
            </Button>
        </div>
    );
}

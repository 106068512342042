import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SignedInUser from "./signedInUser";
import AdminHomePage from "./adminHomePage";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

const userSettingsStorage = JSON.parse(
    localStorage.getItem("userSettingsDoc")
) || { email: "", favorites: [], hidden: [], radius: 70, isAdmin: true };

export default function UserScreen({
    signOut,
    userSettingsDoc,
    adminPartyList,
    handleGoToApprovalEditCard,
    handleApprovalCheckClick,
    handleApprovalXClick,
    deleteFromAdminPartyList,
    checkFavoritesIncludes,
    checkHiddenIncludes,
    handlePartyCardCityButton,
    radius,
    onSliderChange,
    handleFavoriteClick,
    handleHiddenClick
}) {
    const classes = useStyles();

    return (
        <div>
            {userSettingsDoc.isAdmin && adminPartyList ? (
                <AdminHomePage
                    signOut={signOut}
                    userSettingsDoc={userSettingsDoc}
                    adminPartyList={adminPartyList}
                    handleGoToApprovalEditCard={handleGoToApprovalEditCard}
                    deleteFromAdminPartyList={deleteFromAdminPartyList}
                    handleApprovalCheckClick={handleApprovalCheckClick}
                    handleApprovalXClick={handleApprovalXClick}
                ></AdminHomePage>
            ) : (
                <SignedInUser
                    signOut={signOut}
                    userSettingsDoc={userSettingsDoc}
                    checkFavoritesIncludes={checkFavoritesIncludes}
                    checkHiddenIncludes={checkHiddenIncludes}
                    handlePartyCardCityButton={handlePartyCardCityButton}
                    radius={radius}
                    onSliderChange={onSliderChange}
                    handleFavoriteClick={handleFavoriteClick}
                    handleHiddenClick={handleHiddenClick}
                ></SignedInUser>
            )}
        </div>
    );
}

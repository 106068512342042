import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SharePartyCard from "./sharePartyCard";
import SkeletonLoaderPartyCard from "./skeletonLoaderPartyCard";
import { useParams } from "react-router-dom";
import db from "../firebase";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

export default function SharePartyScreen(props) {
    const [hasFetchedShareParty, setHasFetchedShareParty] = useState(false);
    const { uid } = useParams();

    const errorParty = {
        address: "",
        approvalPhase: "3",
        approved: true,
        cityName: "",
        fullDescription: "",
        geopoint: { lat: 0, lng: 0 },
        link: "",
        partyDate: { day: "00", month: "00", year: "0000" },
        smallDescription: "",
        time: "00:00AM",
        timeSegments: { ampm: "AM", hour: "00", minutes: "00" },
        title: "There was an error retreiving your event",
        type: "city",
        uid: "0flvmypuDD5M1HLkPAlF",
        zipCode: "50058"
    };

    const [fetchedParty, setFetchedParty] = useState(errorParty);

    const fetchShareParty = () => {
        // fetch party
        try {
            console.log("UID IN FETCH SHARE", uid);
            db.collection("Parties")
                .doc(uid)
                .get()
                .then((snapshot) => {
                    const fetchedParty = snapshot.data();
                    if (fetchedParty.cityName) {
                        setFetchedParty(fetchedParty);
                        console.log("snapshot data", snapshot.data());
                        setHasFetchedShareParty(true);
                    }
                })
                .catch((error) => {
                    console.log("failed to fetch", error);
                    setHasFetchedShareParty(true);
                });
        } catch (error) {
            setHasFetchedShareParty(true);
            console.log("couldnt get event", error);
        }
    };

    useEffect(() => {
        fetchShareParty();

        console.log("uid from route", uid);

        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        );

        const isIos =
            !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

        console.log("ISIOS", isIos);
        console.log("isMobile", isMobile);
        //THIS IS CAUSING THE FUNC.APPLY ERROR
        if (isMobile) {
            if (isIos) {
                window.location.href =
                    "neighbored://ShareParty/ShareParty?uid=" + uid;
                // setTimeout(() => {
                //     window.location.href =
                //         "https://apps.apple.com/us/app/neighbored/id1545220147";
                // }, 1000);
            } else {
                console.log("android scheme change ");
                window.location.href =
                    "neighbored://ShareParty/ShareParty?uid=" + uid;
                // setTimeout(() => {
                //     window.location.href =
                //         "https://play.google.com/store/apps/details?id=com.neighboredllc.neighbored";
                // }, 1000);
            }
        }
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                justifyContent: "center",
                marginTop: "10rem"
            }}
        >
            {hasFetchedShareParty ? (
                <SharePartyCard
                    party={fetchedParty}
                    userSettingsDoc={props.userSettingsDoc}
                    handlePartyCardCityButton={props.handlePartyCardCityButton}
                    checkFavoritesIncludes={props.checkFavoritesIncludes}
                    checkHiddenIncludes={props.checkHiddenIncludes}
                    handleFavoriteClick={props.handleFavoriteClick}
                    handleHiddenClick={props.handleHiddenClick}
                ></SharePartyCard>
            ) : (
                <SkeletonLoaderPartyCard></SkeletonLoaderPartyCard>
            )}
        </div>
    );
}

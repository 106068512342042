import db from "../firebase";

let chunkArray = (array, size) => {
    let result = [];
    for (let i = 0; i < array.length; i += size) {
        let chunk = array.slice(i, i + size);
        result.push(chunk);
    }
    return result;
};

export default async function fillPartyList(queryList) {
    let firestoreParties = [];

    var citiesMap = queryList.reduce(function(map, obj) {
        map[obj.name] = { cityInRadius: true, isSignedUp: obj.isSignedUp };
        return map;
    }, {});

    let snapshot = await db.collection("Parties").get();
    snapshot.forEach((snap) => {
        firestoreParties.push({ ...snap.data(), uid: snap.id });
    });
    return firestoreParties.filter((party) => {
        return (
            citiesMap[party.cityName] &&
            citiesMap[party.cityName].cityInRadius &&
            citiesMap[party.cityName].isSignedUp
        );
    });
}

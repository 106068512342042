import React, { Component } from "react";
import PartyCard from "./partyCard";
import { DateSorter } from "../TwoZeroFunctions/dateSorter";

class FavoritedEvents extends Component {
    render() {
        let favoritedEvents = this.props.favorites.filter((party) => {
            if (party.approved) {
                return party;
            }
        });
        // .filter((party) => {
        //     if (
        //         party.title
        //             .toLowerCase()
        //             .includes(this.props.searchFilter.toLowerCase())
        //     ) {
        //         return party;
        //     }
        // });
        let sortedFavoritedEvents = DateSorter(favoritedEvents);
        return (
            <div style={{ maxHeight: "57.5vh", overflowY: "scroll" }}>
                {sortedFavoritedEvents.map((party) => (
                    <PartyCard
                        checkFavoritesIncludes={
                            this.props.checkFavoritesIncludes
                        }
                        checkHiddenIncludes={this.props.checkHiddenIncludes}
                        party={party}
                        key={party.uid}
                        userSettingsDoc={this.props.userSettingsDoc}
                        handlePartyCardCityButton={
                            this.props.handlePartyCardCityButton
                        }
                        handleFavoriteClick={this.props.handleFavoriteClick}
                        handleHiddenClick={this.props.handleHiddenClick}
                    />
                ))}
            </div>
        );
    }
}

export default FavoritedEvents;

import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import OpeArt from "./opeArtNLogo";
import Button from "@material-ui/core/Button";

const NoPartiesCard = ({}) => {
    return (
        <Card className="noCard">
            <CardContent className="bottomSignCard">
                <OpeArt></OpeArt>
                <p>
                    There are no approved events near you. Would you like to see
                    all Neighbored Events?
                </p>
                <Button
                    variant="contained"
                    color="primary"
                    href="#contained-buttons"
                    name="showAllEvents"
                    id="showAllEventsButton"
                    onClick={() => console.log("show all events")}
                >
                    Yes
                </Button>
                <br></br>
            </CardContent>
        </Card>
    );
};

export default NoPartiesCard;

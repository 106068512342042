import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import NeighboredCircle from "../images/NeighboredLogoV2.png";

const useStyles = makeStyles({
    img: {
        justifyContent: "center",
        justifyItems: "center",
        justifySelf: "center",
        width: "35%"
    }
});

export default function OpeArt() {
    const classes = useStyles();
    return (
        <CardMedia
            component="img"
            alt="Neighbored Logo"
            className={classes.img}
            image={NeighboredCircle}
            title="Neighbored Logo"
        />
    );
}

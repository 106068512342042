import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MidHeadie from "./midHeadie";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import NeighboredCircle from "../images/NeighboredLogoV2.png";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

const userSettingsStorage = JSON.parse(
    localStorage.getItem("userSettingsDoc")
) || { email: "", favorites: [], hidden: [], radius: 70, isAdmin: true };

export default function AdminHomePage({
    signOut,
    userSettingsDoc,
    adminPartyList,
    handleGoToApprovalEditCard,
    handleApprovalCheckClick,
    handleApprovalXClick,
    deleteFromAdminPartyList
}) {
    const classes = useStyles();

    const userSettingsDocCorrect = userSettingsDoc
        ? userSettingsDoc
        : userSettingsStorage;

    const newParties = adminPartyList.filter((party) => {
        return party.approvalPhase === "1";
    });

    const deniedParties = adminPartyList.filter((party) => {
        return party.approvalPhase === "2";
    });

    const approvedParties = adminPartyList.filter((party) => {
        return party.approvalPhase === "3";
    });

    return (
        <div
            className={classes.root}
            style={{ display: "flex", flexDirection: "row", height: "100%" }}
        >
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Card className="topAdminCard">
                    <CardContent
                        className="bottomSignCard"
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <CardMedia
                            component="img"
                            alt="NeighboredCircleLogoV2"
                            width="20px"
                            height="auto"
                            style={{
                                alignSelf: "center"
                            }}
                            className="signUpCardImage"
                            image={NeighboredCircle}
                            title="NeighboredCircleLogoV2"
                        />

                        <Typography variant="body2">
                            {userSettingsDocCorrect.email}
                        </Typography>

                        {/* <Typography variant="body2">
                            {adminPartyList.length}
                        </Typography> */}

                        <Button
                            variant="contained"
                            color="primary"
                            href="#contained-buttons"
                            name="Sign Out"
                            id="signOutButton"
                            onClick={() => signOut()}
                        >
                            Sign Out
                        </Button>
                    </CardContent>
                </Card>
                <Card className="bottomAdminCard">
                    <CardContent
                        className="bottomSignCard"
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <Typography variant="body2">
                            New Events: {newParties.length}
                        </Typography>
                        <Typography variant="body2">
                            Denied Events: {deniedParties.length}
                        </Typography>
                        <Typography variant="body2">
                            Approved Events: {approvedParties.length}
                        </Typography>
                        {/* <Typography variant="body2">
                            {adminPartyList.length}
                        </Typography> */}
                    </CardContent>
                </Card>
            </div>

            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <MidHeadie
                    adminPartyList={adminPartyList}
                    handleGoToApprovalEditCard={handleGoToApprovalEditCard}
                    handleApprovalCheckClick={handleApprovalCheckClick}
                    handleApprovalXClick={handleApprovalXClick}
                    deleteFromAdminPartyList={deleteFromAdminPartyList}
                ></MidHeadie>
            </div>
            {/* half of the screen will be the demographics card looking thing with the radius slider etc and the other half will be the favorites and hidden switch tab */}
        </div>
    );
}

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CheckIcon from "./checkIcon";
import XIcon from "./xIcon";
import BellIcon from "./bellIcon";
import ApprovedApprovalCardList from "./approvedApprovalCardList";
import NewApprovalCardList from "./newApprovalCardList";
import DeniedApprovalCardList from "./deniedApprovalCardList";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: "2.5rem",
        border: "solid",
        borderColor: "black",
        backgroundColor: "#3fb54f",
        marginRight: "1rem",
        borderWidth: ".5px",
        minHeight: "40vh",
        maxHeight: "75vh"
    }
}));

export default function MidHeadie(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                >
                    <Tab icon={<CheckIcon />} {...a11yProps(0)}></Tab>
                    <Tab icon={<XIcon />} {...a11yProps(1)} />
                    <Tab icon={<BellIcon />} {...a11yProps(2)}></Tab>
                </Tabs>
            </AppBar>
            <TabPanel
                value={value}
                style={{
                    backgroundColor: "#3fb54f"
                }}
                index={0}
            >
                Approved
                <ApprovedApprovalCardList
                    handleGoToApprovalEditCard={
                        props.handleGoToApprovalEditCard
                    }
                    adminPartyList={props.adminPartyList}
                    deleteFromAdminPartyList={props.deleteFromAdminPartyList}
                    handleApprovalCheckClick={props.handleApprovalCheckClick}
                    handleApprovalXClick={props.handleApprovalXClick}
                ></ApprovedApprovalCardList>
            </TabPanel>
            <TabPanel
                value={value}
                index={1}
                style={{
                    backgroundColor: "#3fb54f"
                }}
            >
                Disapproved
                <DeniedApprovalCardList
                    handleGoToApprovalEditCard={
                        props.handleGoToApprovalEditCard
                    }
                    adminPartyList={props.adminPartyList}
                    deleteFromAdminPartyList={props.deleteFromAdminPartyList}
                    handleApprovalCheckClick={props.handleApprovalCheckClick}
                    handleApprovalXClick={props.handleApprovalXClick}
                ></DeniedApprovalCardList>
            </TabPanel>
            <TabPanel
                value={value}
                index={2}
                style={{
                    backgroundColor: "#3fb54f"
                }}
            >
                New Events
                <NewApprovalCardList
                    handleGoToApprovalEditCard={
                        props.handleGoToApprovalEditCard
                    }
                    adminPartyList={props.adminPartyList}
                    deleteFromAdminPartyList={props.deleteFromAdminPartyList}
                    handleApprovalCheckClick={props.handleApprovalCheckClick}
                    handleApprovalXClick={props.handleApprovalXClick}
                ></NewApprovalCardList>
            </TabPanel>
        </div>
    );
}

const latLongCalculations = (lat, long, cityLat, cityLong) => {
    let userLatitude = lat;
    let userLongitude = long;
    let mToMiles = 0.000621371;
    const a = (userLatitude * Math.PI) / 180;
    const b = (cityLat * Math.PI) / 180;
    const radChange = ((cityLong - userLongitude) * Math.PI) / 180;
    const R = 6371e3;
    const d =
        Math.acos(
            Math.sin(a) * Math.sin(b) +
                Math.cos(a) * Math.cos(b) * Math.cos(radChange)
        ) * R;
    let distanceInMiles = mToMiles * d;
    return distanceInMiles;
};

export default latLongCalculations;

import React from "react";
import CityCard from "./cityCard";
import CalculateLatLongDistance from "../TwoZeroFunctions/calculateLatLongDistance";
import NoCitiesInRadius from "./noCitiesInRadius";

export default function CityCardList(props) {
    const filteredQueryList = props.citiesInRadius.filter((city) => {
        let distance = CalculateLatLongDistance(
            city.latitude,
            city.longitude,
            props.latitude,
            props.longitude
        );
        if (props.userSettingsDoc) {
            return distance < props.userSettingsDoc.radius;
        } else {
            return true;
        }
    });

    return (
        <div className="cityCardList">
            {filteredQueryList.length > 0 ? (
                filteredQueryList.map((city) => (
                    <CityCard
                        city={city}
                        key={city.name}
                        handleGoToCityPage={props.handleGoToCityPage}
                    />
                ))
            ) : (
                <NoCitiesInRadius></NoCitiesInRadius>
            )}
        </div>
    );
}

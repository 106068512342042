import db from "../firebase";
import * as Sentry from "@sentry/react";

const UpdateUserLogs = (action, userLogs) => {
    db.collection("UserLogs")
        .doc(userLogs.uid)
        .update({ history: [action, ...userLogs.history] })
        .catch(function(error) {
            Sentry.captureException(error);
        });
};

export default UpdateUserLogs;

import * as firebase from "firebase/app";

const SendPasswordReset = (emailAddress, handleClose) => {
    var auth = firebase.auth();

    auth.sendPasswordResetEmail(emailAddress)
        .then(function() {
            // Email sent.
            console.log("email sent");
            handleClose();
        })
        .catch(function(error) {
            // An error happened.
        });
};

export default SendPasswordReset;

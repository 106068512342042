import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import OpeArt from "./opeArtNLogo";

class NoCitiesInRadius extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Card className="noCard">
                <CardContent className="bottomSignCard">
                    <OpeArt></OpeArt>
                    <p>
                        Ope! There are no cities in your radius. Cities in your
                        radius have not signed up for Neighbored. You can still
                        search cities in the search bar. You will not have any
                        events. Email us if you'd like a new area added at
                        neighboredllc@gmail.com
                    </p>
                    <br></br>
                </CardContent>
            </Card>
        );
    }
}

export default NoCitiesInRadius;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const currencies = [
    {
        value: "city",
        label: "Community"
    },
    {
        value: "business",
        label: "Business"
    },
    {
        value: "school",
        label: "School"
    }
];

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "50%"
        }
    }
}));

export default function Selector(props) {
    const classes = useStyles();
    const [currency, setCurrency] = React.useState("EUR");

    const handleChange = (event) => {
        setCurrency(event.target.value);
        props.handleTypeChange(event);
        props.validatePartyType(event.target.value);
    };

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <div>
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Select*"
                    value={currency}
                    onChange={handleChange}
                    helperText="Please select your event type"
                    variant="outlined"
                >
                    {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        </form>
    );
}

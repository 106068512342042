import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SendPasswordReset from "../TwoZeroFunctions/sendPasswordReset";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: "center"
    }
}));

export default function ForgotPasswordModal({ open, handleClose }) {
    const classes = useStyles();
    const [email, setEmail] = useState("");

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">
                            Where should we send the reset?
                        </h2>
                        <TextField
                            id="outlined-basic"
                            label="E-Mail"
                            variant="outlined"
                            name="email"
                            style={{
                                justifyContent: "center",
                                justifySelf: "center"
                            }}
                            value={email}
                            className="formFieldSignIn emailTextField"
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                        />

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "1rem"
                            }}
                        >
                            <Button
                                className="signInButton"
                                size="small"
                                color="primary"
                                id="googleButton"
                                onClick={() => {
                                    SendPasswordReset(email, handleClose);
                                }}
                            >
                                Reset
                            </Button>

                            <Button
                                className="signInButton"
                                size="small"
                                color="primary"
                                id="googleButton"
                                style={{ flex: 1, margin: ".5rem" }}
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

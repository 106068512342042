import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "./textField";
import MultilineTextFields from "./multiLineText";
import Selector from "./selector";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import IconButton from "@material-ui/core/IconButton";
import DateFields from "./dateFields";
import handleFormSubmit from "../TwoZeroFunctions/handleFormSubmit";
import TimeSelector from "./timeSelector";
import { InputLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import CreateEventSuccessModal from "./CreateEventSuccessModal";
import GooglePlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-google-places-autocomplete";
import calculateRecurringDateObjects from "../TwoZeroFunctions/calculateRecurringDateObjects";
import { StylesContext } from "@material-ui/styles";
import InputBase from "@material-ui/core/InputBase";

const GreenCheckbox = withStyles({
    root: {
        color: "#264d26",
        "&$checked": {
            color: "#264d26",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            backgroundColor: theme.palette.background.paper,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}))(InputBase);

export default function CreateAPartyFormFunctionComponent(props) {
    const cityPageCity = JSON.parse(localStorage.getItem("cityPageCity"));
    const [partyTitle, setPartyTitle] = useState("");
    const [partyTitleMessage, setPartyTitleMessage] = useState(" ");
    const [timeMessage, setTimeMessage] = useState(" ");
    const [hour, setHour] = React.useState("01");
    const [minutes, setMinutes] = React.useState("00");
    const [ampm, setAmpm] = React.useState("AM");
    const [hourEnd, setHourEnd] = React.useState("01");
    const [minutesEnd, setMinutesEnd] = React.useState("00");
    const [ampmEnd, setAmpmEnd] = React.useState("AM");
    const [hasEndTime, setHasEndTime] = useState(false);
    const [partyDates, setPartyDates] = useState([
        { day: "", month: "", year: "" },
    ]);
    const [partyDateObjects, setPartyDateObjects] = [];
    const [link, setLink] = useState("");
    const [address, setAddress] = useState("");
    const [addressMessage, setAddressMessage] = useState(" ");
    const [type, setType] = useState("");
    const [typeMessage, setTypeMessage] = useState(" ");
    const [smallDescription, setSmallDescription] = useState("");
    const [smallDescriptionMessage, setSmallDescriptionMessage] = useState(" ");
    const [geopoint, setGeopoint] = useState(null);
    const [fullDescription, setFullDescription] = useState("");
    const [fullDescriptionMessage, setFullDescriptionMessage] = useState(" ");
    const [successfulPostMessage, setSuccessfulPostMessage] = useState(" ");
    const [errorPostMessage, setErrorPostMessage] = useState("");
    const [charsLeft, setCharsLeft] = useState(140);
    const [maxChars, setMaxChars] = useState(140);
    const [bundledParty, setBundledParty] = useState({});
    const [isRecurring, setIsRecurring] = useState(false);
    const [dayOfMonth, setDayOfMonth] = useState("0");
    const [dayOfWeek, setDayOfWeek] = useState("Monday");

    const [open, setOpen] = React.useState(false);

    const [recurringFrequency, setRecurringFrequency] = useState(1);
    const [recurringUnits, setRecurringUnits] = useState("weeks");

    const handleChangeFrequency = (event) => {
        setRecurringFrequency(event.target.value);
    };
    const handleChangeRecurringUnits = (event) => {
        setRecurringUnits(event.target.value);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    const setGeopointFull = (value) => {
        // console.log(
        //     "GEOVALUE just address",
        //     value.value.structured_formatting.main_text
        // );
        setAddress(value.value.structured_formatting.main_text);
        validatePartyAddress(value.value.structured_formatting.main_text);
        geocodeByAddress(value.label).then((results) => {
            // console.log("RESULTSBYADDRESS", results);
            getLatLng(results[0]).then(({ lat, lng }) => {
                // console.log("Successfully got latitude and longitude", {
                //     lat,
                //     lng,
                // });
                setGeopoint({ lat, lng });
            });
        });
    };

    const validatePartyTitle = (titleParam) => {
        if (titleParam.length >= 1 && titleParam.length <= 35) {
            setPartyTitleMessage("");
            setErrorPostMessage("");
        } else {
            setPartyTitleMessage(
                "An Event Title is required and must be less than 35 characters"
            );
            setErrorPostMessage("You need to fix your Event Title");
            setSuccessfulPostMessage("");
        }
    };

    const validatePartyAddress = (addressParam) => {
        if (addressParam.length === 0) {
            setAddressMessage("Address is required");
            setErrorPostMessage("You need to fix your Address");
            setSuccessfulPostMessage("");
        } else {
            setAddressMessage("");
            setErrorPostMessage("");
        }
    };
    const handleChangeDayOfMonth = (event) => {
        setDayOfMonth(event.target.value);
    };
    const handleChangeDayOfWeek = (event) => {
        setDayOfWeek(event.target.value);
    };

    const handleChangeHour = (event) => {
        setHour(event.target.value);
    };
    const handleChangeMinutes = (event) => {
        setMinutes(event.target.value);
    };
    const handleChangeAMPM = (event) => {
        setAmpm(event.target.value);
    };

    const handleChangeHourEnd = (event) => {
        setHourEnd(event.target.value);
    };
    const handleChangeMinutesEnd = (event) => {
        setMinutesEnd(event.target.value);
    };
    const handleChangeAMPMEnd = (event) => {
        setAmpmEnd(event.target.value);
    };

    const validatePartyType = (typeParam) => {
        if (typeParam === "") {
            setTypeMessage("Please select a Type");
            setErrorPostMessage("You need to select a Type");
            setSuccessfulPostMessage("");
        } else {
            setTypeMessage("");
            setErrorPostMessage("");
        }
    };

    const validatePartySmallDescription = (smallDescParam) => {
        if (smallDescParam.length > 140) {
            setSmallDescriptionMessage(
                "A Short Description must be less than 140 characters"
            );
            setErrorPostMessage("You need to fix your Short Description");
            setSuccessfulPostMessage("");
        } else {
            setSmallDescriptionMessage("");
            setErrorPostMessage("");
        }
    };

    const handleWordCount = (event) => {
        const charCount = event.target.value.length;
        const maxChar = maxChars;
        const charLength = maxChar - charCount;
        setCharsLeft(charLength);
    };

    const handleTypeChange = (e) => {
        setType(e.target.value);
    };

    const handleDateChange = (e, index) => {
        const values = [...partyDates];
        values[index][e.target.name] = e.target.value;
        setPartyDates(values);
    };

    const createDateObject = (day, month, year) => {
        var adjustedMonth = month - 1;
        var hourNumber = parseInt(hour);
        var minutesNumber = parseInt(minutes);
        if (ampm === "PM") {
            hourNumber += 12;
        }
        var dateObject = new Date(
            year,
            adjustedMonth,
            day,
            hourNumber,
            minutesNumber,
            0,
            0
        );
        return dateObject;
    };

    const isPartyReady = () => {
        if (partyTitleMessage === "" && addressMessage === "") {
            setErrorPostMessage("");
            return true;
        } else {
            return false;
        }
    };

    const bundleParty = (dateObject, partyDate) => {
        var timeString = hour + ":" + minutes + ampm;
        var party = {
            approved: false,
            cityName: cityPageCity.name,
            approvalPhase: "1",
            time: timeString,
            dateObject: dateObject,
            title: partyTitle,
            partyDate: partyDate,
            address: address,
            geopoint: geopoint,
            type: type,
            zipCode: cityPageCity.zipCode,
            smallDescription: smallDescription,
            fullDescription: fullDescription,
            link: link.trim(),
            timeSegments: hasEndTime
                ? {
                      hour,
                      minutes,
                      ampm,
                      hourEnd,
                      minutesEnd,
                      ampmEnd,
                  }
                : {
                      hour,
                      minutes,
                      ampm,
                  },
        };
        return party;
    };

    const clearForm = () => {
        setPartyTitle("");
        setPartyDates([{ day: "", month: "", year: "" }]);
        setAddress("");
        // setTime("");
        setSmallDescription("");
        setFullDescription("");
        setLink("");
    };

    return (
        <div>
            <h2 className="cityCreateEventTitleSmall">
                You are creating an event in
            </h2>
            <h1 className="cityCreateEventTitleBig">{cityPageCity.name}</h1>
            <form>
                <TextField
                    id="outlined-basic"
                    label="Event Title*"
                    variant="outlined"
                    onChange={(event) => {
                        setPartyTitle(event.target.value);
                        validatePartyTitle(event.target.value);
                    }}
                    name="partyTitle"
                    value={partyTitle}
                    className="formField"
                />
                <h5 className="errorMessage">{partyTitleMessage}</h5>
                <InputLabel
                    id="demo-customized-select-label"
                    style={{ alignSelf: "center", justifySelf: "center" }}
                >
                    Start Time
                </InputLabel>
                <TimeSelector
                    hour={hour}
                    minutes={minutes}
                    ampm={ampm}
                    handleChangeHour={handleChangeHour}
                    handleChangeMinutes={handleChangeMinutes}
                    handleChangeAMPM={handleChangeAMPM}
                ></TimeSelector>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        textAlign: "center",
                    }}
                >
                    <InputLabel
                        id="demo-customized-select-label"
                        style={{ alignSelf: "center", justifySelf: "center" }}
                    >
                        Add End Time?
                    </InputLabel>
                    <GreenCheckbox
                        checked={hasEndTime}
                        onChange={() => setHasEndTime(!hasEndTime)}
                        inputProps={{
                            "aria-label": "primary checkbox",
                        }}
                    />
                </div>
                {hasEndTime ? (
                    <TimeSelector
                        hour={hourEnd}
                        minutes={minutesEnd}
                        ampm={ampmEnd}
                        handleChangeHour={handleChangeHourEnd}
                        handleChangeMinutes={handleChangeMinutesEnd}
                        handleChangeAMPM={handleChangeAMPMEnd}
                    ></TimeSelector>
                ) : null}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        textAlign: "center",
                    }}
                >
                    <InputLabel
                        id="demo-customized-select-label"
                        style={{ alignSelf: "center", justifySelf: "center" }}
                    >
                        Is this a recurring event?
                    </InputLabel>
                    <GreenCheckbox
                        checked={isRecurring}
                        onChange={() => {
                            setIsRecurring(!isRecurring);
                            setPartyDates([partyDates[0]]);
                        }}
                        inputProps={{
                            "aria-label": "primary checkbox",
                        }}
                    />
                </div>
                {isRecurring ? (
                    <div>
                        <InputLabel id="demo-customized-select-label">
                            Repeats every
                        </InputLabel>
                        {recurringUnits === "weeks" ? (
                            <InputLabel id="demo-customized-select-label">
                                {recurringFrequency} week
                                {recurringFrequency === 1 ? "" : "s"} until the
                                end of the year
                            </InputLabel>
                        ) : null}
                        {recurringUnits === "year" ? (
                            <InputLabel id="demo-customized-select-label">
                                {recurringFrequency} year
                                {recurringFrequency === 1 ? "" : "s"} for{" "}
                                {recurringFrequency * 3} years
                            </InputLabel>
                        ) : null}
                        {recurringUnits === "month" ? (
                            <InputLabel id="demo-customized-select-label">
                                {dayOfMonth === "0" ? "1st" : null}{" "}
                                {dayOfMonth === "1" ? "2nd" : null}{" "}
                                {dayOfMonth === "2" ? "3rd" : null}{" "}
                                {dayOfMonth === "5" ? "Last" : null} {dayOfWeek}{" "}
                                of the Month
                            </InputLabel>
                        ) : null}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                            }}
                        >
                            {recurringUnits === "weeks" ||
                            recurringUnits === "year" ? (
                                <FormControl className={classes.margin}>
                                    <NativeSelect
                                        id="demo-customized-select-native"
                                        value={recurringFrequency}
                                        onChange={(event) => {
                                            handleChangeFrequency(event);
                                        }}
                                        input={<BootstrapInput />}
                                    >
                                        <option value={"1"}>1</option>
                                        <option value={"2"}>2</option>
                                        <option value={"3"}>3</option>
                                        {/* <option value={"4"}>4</option> */}
                                    </NativeSelect>
                                </FormControl>
                            ) : null}
                            {recurringUnits === "month" ? (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <FormControl className={classes.margin}>
                                        <NativeSelect
                                            id="demo-customized-select-native"
                                            value={dayOfMonth}
                                            onChange={(event) => {
                                                handleChangeDayOfMonth(event);
                                            }}
                                            input={<BootstrapInput />}
                                        >
                                            <option value={"0"}>1st</option>
                                            <option value={"1"}>2nd</option>
                                            <option value={"2"}>3rd</option>
                                            <option value={"5"}>Last</option>
                                        </NativeSelect>
                                    </FormControl>

                                    <FormControl className={classes.margin}>
                                        <NativeSelect
                                            id="demo-customized-select-native"
                                            value={dayOfWeek}
                                            onChange={(event) => {
                                                handleChangeDayOfWeek(event);
                                            }}
                                            input={<BootstrapInput />}
                                        >
                                            <option value={"Monday"}>
                                                Monday
                                            </option>
                                            <option value={"Tuesday"}>
                                                Tuesday
                                            </option>
                                            <option value={"Wednesday"}>
                                                Wednesday
                                            </option>
                                            <option value={"Thursday"}>
                                                Thursday
                                            </option>
                                            <option value={"Friday"}>
                                                Friday
                                            </option>
                                            <option value={"Saturday"}>
                                                Saturday
                                            </option>
                                            <option value={"Sunday"}>
                                                Sunday
                                            </option>
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                            ) : null}
                            <FormControl className={classes.margin}>
                                <NativeSelect
                                    id="demo-customized-select-native"
                                    value={recurringUnits}
                                    onChange={(event) => {
                                        handleChangeRecurringUnits(event);
                                    }}
                                    input={<BootstrapInput />}
                                >
                                    <option value={"weeks"}>weeks</option>
                                    <option value={"month"}>month</option>
                                    <option value={"year"}>year</option>
                                </NativeSelect>
                            </FormControl>
                        </div>
                    </div>
                ) : null}

                {/* <TextField
                    id="outlined-basic"
                    label="Start Time*"
                    variant="outlined"
                    onChange={(event) => {
                        setTime(event.target.value);
                        validatePartyTime(event.target.value);
                    }}
                    name="time"
                    value={time}
                    className="formField"
                />{" "} */}
                <h5 className="errorMessage">{timeMessage}</h5>

                {isRecurring ? (
                    <InputLabel id="demo-customized-select-label">
                        Starting on this date:
                    </InputLabel>
                ) : null}

                {isRecurring ? null : (
                    <div>
                        <IconButton
                            aria-label="city homepage"
                            onClick={() => {
                                setPartyDates([
                                    ...partyDates,
                                    { day: "", month: "", year: "" },
                                ]);
                            }}
                        >
                            <i class="far fa-calendar-plus"></i>
                        </IconButton>
                        <IconButton
                            aria-label="city homepage"
                            onClick={() => {
                                if (partyDates.length > 1) {
                                    partyDates.pop();
                                    setPartyDates([...partyDates]);
                                }
                            }}
                        >
                            <i class="far fa-calendar-minus"></i>
                        </IconButton>
                    </div>
                )}
                {partyDates.map((inputField, index) => (
                    <DateFields
                        index={index}
                        inputField={inputField}
                        handleDateChange={handleDateChange}
                        day={inputField.day}
                        month={inputField.month}
                        year={inputField.year}
                        setErrorPostMessage={setErrorPostMessage}
                        setSuccessfulPostMessage={setSuccessfulPostMessage}
                    ></DateFields>
                ))}

                <div className="GPADiv">
                    <GooglePlacesAutocomplete
                        apiKey={
                            process.env
                                .REACT_APP_GOOGLE_PLACES_AUTOCOMPLETE_API_KEY
                        }
                        classname="googlePlacesAutocomplete"
                        selectProps={{ geopoint, onChange: setGeopoint }}
                        selectProps={{
                            placeholder: "Address",
                            onChange: (value) => setGeopointFull(value),
                        }}
                    />
                </div>
                <h5 className="errorMessage">{addressMessage}</h5>
                <Selector
                    handleTypeChange={handleTypeChange}
                    validatePartyType={validatePartyType}
                ></Selector>
                <h5 className="errorMessage">{typeMessage}</h5>
                <MultilineTextFields
                    label="Short Description"
                    placeholder="Short Description"
                    maxLength={maxChars}
                    onChange={(event) => {
                        handleWordCount(event);
                        setSmallDescription(event.target.value);
                        validatePartySmallDescription(event.target.value);
                    }}
                    name="smallDescription"
                    value={smallDescription}
                    className="formField"
                ></MultilineTextFields>
                <div>Characters remaining: {charsLeft}</div>
                <h5 className="errorMessage">{smallDescriptionMessage}</h5>
                <MultilineTextFields
                    label="Full Description"
                    name="fullDescription"
                    onChange={(event) => {
                        setFullDescription(event.target.value);
                    }}
                    value={fullDescription}
                    className="formField"
                ></MultilineTextFields>
                <h5 className="errorMessage">{fullDescriptionMessage}</h5>
                <div classname="errorMessage">
                    Make sure to include http:// in front your link, if it's not
                    already there.
                </div>
                <TextField
                    id="outlined-basic"
                    label="Event Link"
                    variant="outlined"
                    onChange={(event) => {
                        setLink(event.target.value);
                    }}
                    name="eventLink"
                    value={link}
                    className="formField"
                />
                <h4>{successfulPostMessage}</h4>
                <h4 className="errorPostMessage">{errorPostMessage}</h4>
                <Button
                    variant="contained"
                    color="primary"
                    href="#contained-buttons"
                    name="Submit"
                    type="submit"
                    onClick={() => {
                        if (isPartyReady()) {
                            if (isRecurring) {
                                const year = partyDates[0].year;
                                const day = partyDates[0].day;
                                const month = partyDates[0].month;
                                let calculatedDates =
                                    calculateRecurringDateObjects(
                                        year,
                                        month,
                                        day,
                                        recurringFrequency,
                                        recurringUnits,
                                        dayOfWeek,
                                        dayOfMonth
                                    );

                                calculatedDates.forEach((partyDate) => {
                                    let { day, month, year } = partyDate;

                                    let dateObject = createDateObject(
                                        day,
                                        month,
                                        year
                                    );
                                    let bundledParty = bundleParty(dateObject, {
                                        day,
                                        month,
                                        year,
                                    });
                                    handleFormSubmit(bundledParty);
                                    let successfulPostMessage =
                                        "[ " +
                                        bundledParty.title +
                                        " ] has been submitted for review";
                                    setSuccessfulPostMessage(
                                        successfulPostMessage
                                    );
                                    setErrorPostMessage("");
                                    setBundledParty(bundledParty);
                                    handleOpen();
                                    clearForm();
                                });
                            } else {
                                partyDates.forEach((partyDate) => {
                                    let { day, month, year } = partyDate;
                                    let dateObject = createDateObject(
                                        day,
                                        month,
                                        year
                                    );
                                    let bundledParty = bundleParty(
                                        dateObject,
                                        partyDate
                                    );
                                    handleFormSubmit(bundledParty);
                                    let successfulPostMessage =
                                        "[ " +
                                        bundledParty.title +
                                        " ] has been submitted for review";
                                    setSuccessfulPostMessage(
                                        successfulPostMessage
                                    );
                                    setErrorPostMessage("");
                                    setBundledParty(bundledParty);
                                    handleOpen();
                                    clearForm();
                                });
                            }
                        } else {
                            setErrorPostMessage(
                                "Party is not ready, please fill out all required fields"
                            );
                        }
                    }}
                >
                    Submit
                </Button>
                <CreateEventSuccessModal
                    party={bundledParty}
                    open={open}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                ></CreateEventSuccessModal>
            </form>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
        minWidth: "100px",
        display: "flex",
    },
}));

import db from "../firebase";
import CreateNewDbUserLog from "./createNewDbUserLog";

const CreateNewDbUser = (user, zipCode) => {
    var dateCreated = new Date();
    var dbUser = db
        .collection("Users")
        .doc(user.uid)
        .set({
            email: user.email,
            uid: user.uid,
            favorites: [],
            isAdmin: false,
            hidden: [],
            radius: 70,
            zipcode: zipCode,
            dateCreated: dateCreated
        });
    CreateNewDbUserLog(user.uid);
    return dbUser;
};

export default CreateNewDbUser;

import db from "../firebase";
import * as Sentry from "@sentry/react";

const DeleteParty = (deleteParty) => {
    let updatePromise = db
        .collection("Parties")
        .doc(deleteParty.uid)
        .delete()
        .then(() => {
            console.log("ITHASBEENDELETED");
        })
        .catch((error) => {
            Sentry.captureException(error);
        });
    return Promise.resolve(updatePromise);
};

export default DeleteParty;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "./textField";
import MultilineTextFields from "./multiLineText";
import HandleEditPartyFormSave from "../TwoZeroFunctions/hanleEditPartyFormSave";
import TimeSelector from "./timeSelector";
import { InputLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

const GreenCheckbox = withStyles({
    root: {
        color: "#264d26",
        "&$checked": {
            color: "#264d26"
        }
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />);

export default function EditPartyForm({ changeScreen, updateAdminPartyList }) {
    const party = JSON.parse(localStorage.getItem("editParty"));
    const [partyTitle, setPartyTitle] = useState(party.title);
    const [partyTitleMessage, setPartyTitleMessage] = useState("");
    const [time, setTime] = useState(party.time);

    const [hour, setHour] = React.useState(
        party.timeSegments ? party.timeSegments.hour : "01"
    );
    const [minutes, setMinutes] = React.useState(
        party.timeSegments ? party.timeSegments.minutes : "00"
    );
    const [ampm, setAmpm] = React.useState(
        party.timeSegments ? party.timeSegments.ampm : "AM"
    );
    const [hourEnd, setHourEnd] = React.useState(
        party.timeSegments.hourEnd ? party.timeSegments.hourEnd : null
    );
    const [minutesEnd, setMinutesEnd] = React.useState(
        party.timeSegments.minutesEnd ? party.timeSegments.minutesEnd : null
    );
    const [ampmEnd, setAmpmEnd] = React.useState(
        party.timeSegments.ampmEnd ? party.timeSegments.ampmEnd : null
    );

    const [timeMessage, setTimeMessage] = useState("");
    const [dayErrorMessage, setDayErrorMessage] = useState("");
    const [monthErrorMessage, setMonthErrorMessage] = useState("");
    const [yearErrorMessage, setYearErrorMessage] = useState("");
    const [address, setAddress] = useState(party.address);
    const [addressMessage, setAddressMessage] = useState("");
    const [day, setDay] = useState(party.partyDate.day);
    const [month, setMonth] = useState(party.partyDate.month);
    const [year, setYear] = useState(party.partyDate.year);
    const [link, setLink] = useState(party.link);
    const date = {
        day: day,
        month: month,
        year: year
    };
    const [smallDescription, setSmallDescription] = useState(
        party.smallDescription
    );
    const [smallDescriptionMessage, setSmallDescriptionMessage] = useState("");

    const [fullDescription, setFullDescription] = useState(
        party.fullDescription
    );
    const [successfulPostMessage, setSuccessfulPostMessage] = useState(" ");
    const [errorPostMessage, setErrorPostMessage] = useState("");
    const [charsLeft, setCharsLeft] = useState(140);
    const [maxChars, setMaxChars] = useState(140);
    const [hasEndTime, setHasEndTime] = useState(false);
    const validatePartyTitle = (titleParam) => {
        if (titleParam.length >= 1 && titleParam.length <= 35) {
            setPartyTitleMessage("");
            setErrorPostMessage("");
        } else {
            setPartyTitleMessage(
                "An Event Title is required and must be less than 35 characters"
            );
            setErrorPostMessage("You need to fix your Event Title");
            setSuccessfulPostMessage("");
        }
    };

    const validatePartyAddress = (addressParam) => {
        if (addressParam.length === 0) {
            setAddressMessage("Address is required");
            setErrorPostMessage("You need to fix your Address");
            setSuccessfulPostMessage("");
        } else {
            setAddressMessage("");
            setErrorPostMessage("");
        }
    };

    const handleChangeHour = (event) => {
        setHour(event.target.value);
    };
    const handleChangeMinutes = (event) => {
        setMinutes(event.target.value);
    };
    const handleChangeAMPM = (event) => {
        setAmpm(event.target.value);
    };

    const handleChangeHourEnd = (event) => {
        setHourEnd(event.target.value);
    };
    const handleChangeMinutesEnd = (event) => {
        setMinutesEnd(event.target.value);
    };
    const handleChangeAMPMEnd = (event) => {
        setAmpmEnd(event.target.value);
    };

    const validatePartyTime = (timeParam) => {
        if (timeParam.length === 0) {
            setTimeMessage("You must have a time");
            setErrorPostMessage("You need to fix your Time");
            setSuccessfulPostMessage("");
        } else {
            setTimeMessage("");
            setErrorPostMessage("");
        }
    };

    const validatePartySmallDescription = (smallDescParam) => {
        if (smallDescParam.length > 140) {
            setSmallDescriptionMessage(
                "A Short Description must be less than 140 characters"
            );
            setErrorPostMessage("You need to fix your Short Description");
            setSuccessfulPostMessage("");
        } else {
            setSmallDescriptionMessage("");
            setErrorPostMessage("");
        }
    };

    const handleWordCount = (event) => {
        const charCount = event.target.value.length;
        const maxChar = maxChars;
        const charLength = maxChar - charCount;
        setCharsLeft(charLength);
    };

    const handleDayChange = (day) => {
        setDay(day);
    };

    const handleMonthChange = (month) => {
        setMonth(month);
    };

    const handleYearChange = (year) => {
        setYear(year);
    };
    const isnum = (value) => {
        let isNum = /^\d+$/.test(value);
        return isNum;
    };

    const validateDomesticPartyDay = (day) => {
        if (day.length === 2 && isnum(day)) {
            setDayErrorMessage("");
            setErrorPostMessage("");
        } else {
            setDayErrorMessage("Format the day as DD");
            setErrorPostMessage("You need to fix your Day");
            setSuccessfulPostMessage("");
        }
    };
    const validateDomesticPartyMonth = (month) => {
        if (month.length === 2 && isnum(month)) {
            setMonthErrorMessage("");
            setErrorPostMessage("");
        } else {
            setMonthErrorMessage("Format the month as MM");
            setErrorPostMessage("You need to fix your Month");
            setSuccessfulPostMessage("");
        }
    };
    const validateDomesticPartyYear = (year) => {
        if (year.length === 4 && isnum(year)) {
            setYearErrorMessage("");
            setErrorPostMessage("");
        } else {
            setYearErrorMessage("Format the year as YYYY");
            setErrorPostMessage("You need to fix your Year");
            setSuccessfulPostMessage("");
        }
    };

    const createDateObject = () => {
        var dateObject = {};
        if (party.timeSegments) {
            let hourNumber = parseInt(hour);
            let adjustedMonth = month - 1;
            let minutesNumber = parseInt(minutes);
            let ampmHour = ampm === "PM" ? hourNumber + 12 : hourNumber;
            dateObject = new Date(
                year,
                adjustedMonth,
                day,
                ampmHour,
                minutesNumber,
                0,
                0
            );
        } else {
            let adjustedMonth = month - 1;
            dateObject = new Date(year, adjustedMonth, day, 0, 0, 0, 0);
        }
        return dateObject;
    };

    const isPartyReady = () => {
        if (partyTitleMessage === "" && addressMessage === "") {
            setErrorPostMessage("");
            return true;
        } else {
            return false;
        }
    };

    const bundlePartyAndUpdateState = (dateObject) => {
        if (party.timeSegments) {
            var timeString = hour + ":" + minutes + ampm;
            var bundledParty = {
                ...party,
                time: timeString,
                dateObject,
                title: partyTitle,
                partyDate: { day, month, year },
                address,
                smallDescription,
                fullDescription,
                link: link.trim(),
                timeSegments: {
                    hour,
                    minutes,
                    ampm,
                    hourEnd,
                    minutesEnd,
                    ampmEnd
                }
            };
            updateAdminPartyList(bundledParty);
        } else {
            const bundledParty = {
                ...party,
                partyTitle,
                date,
                address,
                time,
                smallDescription,
                fullDescription,
                dateObject,
                link
            };
            updateAdminPartyList(bundledParty);
        }
    };

    return (
        <div>
            <h2 className="cityCreateEventTitleSmall">
                You are editing an event
            </h2>
            <form>
                <TextField
                    id="outlined-basic"
                    label="Event Title*"
                    variant="outlined"
                    onChange={(event) => {
                        setPartyTitle(event.target.value);
                        validatePartyTitle(event.target.value);
                    }}
                    name="partyTitle"
                    value={partyTitle}
                    className="formField"
                />
                <h5 className="errorMessage">{partyTitleMessage}</h5>
                <InputLabel
                    id="demo-customized-select-label"
                    style={{
                        alignSelf: "center",
                        justifySelf: "center"
                    }}
                >
                    Start Time
                </InputLabel>
                {party.timeSegments ? (
                    <TimeSelector
                        hour={hour}
                        minutes={minutes}
                        ampm={ampm}
                        handleChangeHour={handleChangeHour}
                        handleChangeMinutes={handleChangeMinutes}
                        handleChangeAMPM={handleChangeAMPM}
                    ></TimeSelector>
                ) : (
                    <TextField
                        id="outlined-basic"
                        label="Start Time*"
                        variant="outlined"
                        onChange={(event) => {
                            setTime(event.target.value);
                            validatePartyTime(event.target.value);
                        }}
                        name="time"
                        value={time}
                        className="formField"
                    />
                )}
                {party.timeSegments.hourEnd ? (
                    <div>
                        <InputLabel
                            id="demo-customized-select-label"
                            style={{
                                alignSelf: "center",
                                justifySelf: "center"
                            }}
                        >
                            End Time
                        </InputLabel>
                        <TimeSelector
                            hour={hourEnd}
                            minutes={minutesEnd}
                            ampm={ampmEnd}
                            handleChangeHour={handleChangeHourEnd}
                            handleChangeMinutes={handleChangeMinutesEnd}
                            handleChangeAMPM={handleChangeAMPMEnd}
                        ></TimeSelector>
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignContent: "center",
                            textAlign: "center"
                        }}
                    >
                        <InputLabel
                            id="demo-customized-select-label"
                            style={{
                                alignSelf: "center",
                                justifySelf: "center"
                            }}
                        >
                            Add End Time?
                        </InputLabel>
                        <GreenCheckbox
                            checked={hasEndTime}
                            onChange={() => setHasEndTime(!hasEndTime)}
                            inputProps={{
                                "aria-label": "primary checkbox"
                            }}
                        />
                    </div>
                )}
                {hasEndTime ? (
                    <TimeSelector
                        hour={hourEnd}
                        minutes={minutesEnd}
                        ampm={ampmEnd}
                        handleChangeHour={handleChangeHourEnd}
                        handleChangeMinutes={handleChangeMinutesEnd}
                        handleChangeAMPM={handleChangeAMPMEnd}
                    ></TimeSelector>
                ) : null}

                <h5 className="errorMessage">{timeMessage}</h5>
                <div className="dateFields">
                    <TextField
                        id="outlined-basic month"
                        label="MM*"
                        variant="outlined"
                        onChange={(event) => {
                            handleMonthChange(event.target.value);
                            validateDomesticPartyMonth(event.target.value);
                        }}
                        name="month"
                        value={month}
                    />
                    <TextField
                        id="outlined-basic day"
                        label="DD*"
                        variant="outlined"
                        onChange={(event) => {
                            handleDayChange(event.target.value);
                            validateDomesticPartyDay(event.target.value);
                        }}
                        name="day"
                        value={day}
                    />
                    <TextField
                        id="outlined-basic year"
                        label="YYYY*"
                        variant="outlined"
                        onChange={(event) => {
                            handleYearChange(event.target.value);
                            validateDomesticPartyYear(event.target.value);
                        }}
                        name="year"
                        value={year}
                    />
                </div>
                <div className="dateFieldErrorMessages">
                    <h5
                        className={
                            monthErrorMessage === "" ? "hidden" : "errorMessage"
                        }
                    >
                        {monthErrorMessage}
                    </h5>
                    <h5
                        className={
                            dayErrorMessage === "" ? "hidden" : "errorMessage"
                        }
                    >
                        {dayErrorMessage}
                    </h5>
                    <h5
                        className={
                            yearErrorMessage === "" ? "hidden" : "errorMessage"
                        }
                    >
                        {yearErrorMessage}
                    </h5>
                </div>
                <TextField
                    id="outlined-basic"
                    label="Address*"
                    variant="outlined"
                    onChange={(event) => {
                        setAddress(event.target.value);
                        validatePartyAddress(event.target.value);
                    }}
                    name="address"
                    value={address}
                    className="formField"
                />
                <h5 className="errorMessage">{addressMessage}</h5>
                <MultilineTextFields
                    label="Short Description*"
                    placeholder="Short Description"
                    maxLength={maxChars}
                    onChange={(event) => {
                        handleWordCount(event);
                        setSmallDescription(event.target.value);
                        validatePartySmallDescription(event.target.value);
                    }}
                    name="smallDescription"
                    value={smallDescription}
                    className="formField"
                ></MultilineTextFields>
                <div>Characters remaining: {charsLeft}</div>
                <h5 className="errorMessage">{smallDescriptionMessage}</h5>
                <MultilineTextFields
                    label="Full Description"
                    name="fullDescription"
                    onChange={(event) => {
                        setFullDescription(event.target.value);
                    }}
                    value={fullDescription}
                    className="formField"
                ></MultilineTextFields>
                <div classname="errorMessage">
                    Make sure to include http:// in front your link, if it's not
                    already there.
                </div>
                <TextField
                    id="outlined-basic"
                    label="Event Link"
                    variant="outlined"
                    onChange={(event) => {
                        setLink(event.target.value);
                    }}
                    name="eventLink"
                    value={link}
                    className="formField"
                />
                <h4>{successfulPostMessage}</h4>
                <h4 className="errorPostMessage">{errorPostMessage}</h4>
                <Button
                    variant="contained"
                    color="primary"
                    href="#contained-buttons"
                    name="Submit"
                    style={{ marginRight: "1rem" }}
                    type="submit"
                    onClick={() => {
                        changeScreen("userScreen");
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    href="#contained-buttons"
                    name="Submit"
                    type="submit"
                    onClick={() => {
                        if (isPartyReady()) {
                            let dateObject = createDateObject();

                            if (party.timeSegments) {
                                let timeSegments = {};
                                party.timeSegments.hourEnd || hasEndTime
                                    ? (timeSegments = {
                                          hour,
                                          minutes,
                                          ampm,
                                          hourEnd,
                                          minutesEnd,
                                          ampmEnd
                                      })
                                    : (timeSegments = { hour, minutes, ampm });

                                var time = hour + ":" + minutes + ampm;
                                HandleEditPartyFormSave(
                                    partyTitle,
                                    date,
                                    address,
                                    time,
                                    smallDescription,
                                    fullDescription,
                                    party.uid,
                                    dateObject,
                                    link,
                                    timeSegments
                                );
                            } else {
                                let timeSegments = { hour, minutes, ampm };
                                HandleEditPartyFormSave(
                                    partyTitle,
                                    date,
                                    address,
                                    time,
                                    smallDescription,
                                    fullDescription,
                                    party.uid,
                                    dateObject,
                                    link,
                                    timeSegments
                                );
                            }

                            let successfulPostMessage =
                                "[ " + partyTitle + " ] has been updated";
                            setSuccessfulPostMessage(successfulPostMessage);
                            setErrorPostMessage("");
                            changeScreen("userScreen");
                            bundlePartyAndUpdateState(dateObject);
                        } else {
                            setErrorPostMessage(
                                "Party is not ready, please fill out all required fields"
                            );
                        }
                    }}
                >
                    Submit
                </Button>
            </form>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({}));

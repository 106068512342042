import React from "react";
import Button from "@material-ui/core/Button";
import CityPartyCardList from "./cityPartyCardList";

export default function CityPage(props) {
    const cityPageCity = JSON.parse(localStorage.getItem("cityPageCity"));

    return (
        <div>
            <h1 className="cityPageTitle">{cityPageCity.name}</h1>
            <div className="cityPageButtons">
                <Button
                    variant="contained"
                    color="primary"
                    href="#contained-buttons"
                    name="Create an Event"
                    className="cityPageButton"
                    onClick={props.handleGoToCreateAnEvent}
                >
                    Create an Event
                </Button>
                {/* {props.city.mediaLink ? (
                    <Button
                        variant="contained"
                        color="primary"
                        target="_blank"
                        href={cityPageCity.mediaLink}
                        name="Media Link"
                        className="cityPageButton"
                    >
                        Media Link
                    </Button>
                ) : null} */}
            </div>

            <CityPartyCardList
                page={props.page}
                partyList={props.partyList}
                checkFavoritesIncludes={props.checkFavoritesIncludes}
                checkHiddenIncludes={props.checkHiddenIncludes}
                handlePartyCardCityButton={props.handlePartyCardCityButton}
                handleFavoriteClick={props.handleFavoriteClick}
                handleHiddenClick={props.handleHiddenClick}
                userSettingsDoc={props.userSettingsDoc}
                cityPageCity={cityPageCity}
            />
        </div>
    );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import HiddenEyeIcon from "./hiddenEyeIcon";
import StarIcon from "./starIcon";
import FavoritedEvents from "./favoritedEvents";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: "2.5rem",
        border: "solid",
        borderColor: "black",
        backgroundColor: "#3fb54f",
        marginRight: "1rem",
        borderWidth: ".5px",
        minHeight: "40vh",
        maxHeight: "75vh"
    }
}));

export default function MidHeadieUser({
    userSettingsDoc,
    checkFavoritesIncludes,
    handlePartyCardCityButton,
    handleFavoriteClick,
    checkHiddenIncludes,
    handleHiddenClick
}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setFavorites(userSettingsDoc.favorites);
        setHidden(userSettingsDoc.hidden);
    });

    const [favorites, setFavorites] = useState(userSettingsDoc.favorites);
    const [hidden, setHidden] = useState(userSettingsDoc.hidden);

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                >
                    <Tab icon={<StarIcon />} {...a11yProps(0)}></Tab>
                    <Tab icon={<HiddenEyeIcon />} {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel
                value={value}
                index={0}
                style={{
                    backgroundColor: "#3fb54f"
                }}
            >
                Favorites
                <FavoritedEvents
                    favorites={favorites}
                    checkFavoritesIncludes={checkFavoritesIncludes}
                    checkHiddenIncludes={checkHiddenIncludes}
                    handlePartyCardCityButton={handlePartyCardCityButton}
                    handleFavoriteClick={handleFavoriteClick}
                    handleHiddenClick={handleHiddenClick}
                    userSettingsDoc={userSettingsDoc}
                ></FavoritedEvents>
            </TabPanel>
            <TabPanel value={value} index={1}>
                Hidden
                <Typography variant="subtitle2">
                    (Clicking the Eye icon on an event hides it from your
                    timeline and places it here)
                </Typography>
                <FavoritedEvents
                    favorites={hidden}
                    checkFavoritesIncludes={checkFavoritesIncludes}
                    checkHiddenIncludes={checkHiddenIncludes}
                    handlePartyCardCityButton={handlePartyCardCityButton}
                    handleFavoriteClick={handleFavoriteClick}
                    handleHiddenClick={handleHiddenClick}
                    userSettingsDoc={userSettingsDoc}
                ></FavoritedEvents>
            </TabPanel>
        </div>
    );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import DeleteParty from "../TwoZeroFunctions/deleteParty";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

export default function DeletePartyModal({
    open,
    handleClose,
    deleteParty,
    deleteFromAdminPartyList
}) {
    const classes = useStyles();
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">
                            Are you sure you want to delete [{deleteParty.title}
                            ] this action cannot be undone
                        </h2>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "1rem"
                            }}
                        >
                            <Button
                                className="signInButton"
                                size="small"
                                color="primary"
                                id="googleButton"
                                style={{ flex: 1, margin: ".5rem" }}
                                onClick={() => {
                                    DeleteParty(deleteParty).then(() => {
                                        console.log("its deleted");
                                        deleteFromAdminPartyList(deleteParty);
                                        handleClose();
                                    });
                                }}
                            >
                                Delete
                            </Button>

                            <Button
                                className="signInButton"
                                size="small"
                                color="primary"
                                id="googleButton"
                                style={{ flex: 1, margin: ".5rem" }}
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import OpeArt from "./opeArtNLogo";

class NoLocationServices extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Card className="noCard">
                <CardContent className="bottomSignCard">
                    <OpeArt></OpeArt>
                    <p>
                        Ope! Neighbored needs location services to show you
                        events in your area.
                    </p>
                    <br></br>
                </CardContent>
            </Card>
        );
    }
}

export default NoLocationServices;

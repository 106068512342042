const cities = [
  { name: "Carroll", latitude: 42.066, longitude: -94.868, id: "8PXA8IRcs8deBRrcN4ms", zipCode: "51401", isSignedUp: true },
  { name: "Audubon", latitude: 41.741, longitude: -94.928, id: "qutVRBpCDmjZhscbjK0a", zipCode: "50025", isSignedUp: true },
  { name: "Coon Rapids", latitude: 41.871, longitude: -94.688, id: "Mg1JN0nBBRBOTgLdexhe", zipCode: "50058", isSignedUp: true, mediaLink: "https://www.coonrapidsenterprise.com/" },
  {name: "Glidden", latitude: 42.096, longitude: -94.711, id: "wPVez3qAkDR5vqtjMXYd", zipCode: "51443", isSignedUp: true },
  { name: "Scranton", latitude: 42.022, longitude: -94.564, id: "h3Z2OoFh841AN4WftUNK", zipCode: "51462", isSignedUp: true },
  { name: "Vail", latitude: 42.073, longitude: -95.203, id: "4YPp0X4DWy3rLfHXWgEK", zipCode: "51465", isSignedUp: true },
  { name: "Elk Horn", latitude: 41.597, longitude: -95.072, id: "WyGa3gFcee3bfjQQj5Ut", zipCode: "51531", isSignedUp: true },
  {name: "Dedham", latitude: 41.9140, longitude: -94.8150, id: "4hpHz2jpjsLbSfRL1n0l", zipCode: "51440", isSignedUp: true}
  ,{name: "Bayard", latitude: 41.8500, longitude: -94.5560, id: "qhFy8VLrh2jclnVF3woq", zipCode: "50029", isSignedUp: true}
  ,{name: "Templeton", latitude: 41.9100, longitude: -94.9280, id: "XLuRwymLUHexja7iNMPu", zipCode: "51463", isSignedUp: true}
  ,{name: "Ralston", latitude: 42.0410, longitude: -94.6330, id: "ajZleiTiu8xDdSWJaFIx", zipCode: "51459", isSignedUp: true}
  ,{name: "Manning", latitude: 41.9140, longitude: -95.0690, id: "QRhwVT4Fb1hxkfWwyQNm", zipCode: "51455", isSignedUp: true}
  ,{name: "Kimballton", latitude: 41.6280, longitude: -95.0730, id: "iT2N1MF0tyD4FuC5WgQZ", zipCode: "51543", isSignedUp: true}
  ,{name: "Manilla", latitude: 41.8860, longitude: -95.2220, id: "ZC8ZZqJxxBDWnq7d4uT8", zipCode: "00004", isSignedUp: true}
  ,{ name: "Bagley", latitude: 41.8530, longitude: -94.4390, id: "eUjZvoOWaZYFUsxtMaqj" , zipCode: "50026", isSignedUp: true}
  ,{ name: "Jamaica", latitude: 41.8560, longitude: -94.3000, id: "4lkivOFTGPIazl2Py3vK" , zipCode: "50128", isSignedUp: true}
  ,{name: "Breda", latitude: 42.1870, longitude: -95.0050, id: "z3PYzEnt73TyirSko0FU", zipCode: "51436", isSignedUp: true}
  ,{ name: "Lidderdale", latitude: 42.1270, longitude: -94.7840, id: "26ysPFWen743Bced6aOx" , zipCode: "51452", isSignedUp: true}
  ,{name: "Arcadia", latitude: 42.0980, longitude: -95.0240, id: "MEUenzxeUQi8paZis8e2", zipCode: "51430", isSignedUp: true},
  { name: "Willey", latitude: 41.979, longitude: -94.822, id: "TJtPJa99b1QVj4ejLO4g", zipCode: "00002", isSignedUp: true },
  {name: "Greenfield",latitude: 41.309,longitude: -94.424,id: "MFX6YblLilvYr9mK6C1J", zipCode: "50849", isSignedUp: true }
,{ name: "Jefferson", latitude: 42.0180, longitude: -94.3790, id: "INUYW3IhPhVetiyX8nGy" , zipCode: "50129", isSignedUp: true},
  {name: "Guthrie Center",latitude: 41.69,longitude: -94.526,id: "2XGBPO63XTo06InUNwbk",zipCode: "50115", isSignedUp: true },
  { name: "Exira", latitude: 41.592, longitude: -94.87, id: "Yfn8yVNyLGMQseJ352xY", zipCode: "50076", isSignedUp: true },
  { name: "Adair", latitude: 41.508, longitude: -94.648, id: "Le5GWCWQA2RPWNTloHUe", zipCode: "50002", isSignedUp: true },
  ,{name: "Atlantic", latitude: 41.4100, longitude: -95.0090, id: "skA7M3V0xRZyuOXczR7P", zipCode: "50022", isSignedUp: true},
  { name: "Stuart", latitude: 41.5, longitude: -94.327, id: "x9YFSIfekAsMl4rHZewY", zipCode: "50250", isSignedUp: true },
  { name: "Anita", latitude: 41.446, longitude: -94.758, id: "y8WxEZOlyog7lIcCbEW5", zipCode: "50020", isSignedUp: true },
  { name: "Griswold", latitude: 41.234, longitude: -95.136, id: "NGbFHIzzeAOKKFFzyF0R", zipCode: "51535", isSignedUp: true },  
  { name: "Oakland", latitude: 41.321, longitude: -95.397, id: "Q9ooTRQ6YRS0nrYYXWEf", zipCode: "51560", isSignedUp: true },
  { name: "Winterset", latitude: 41.333, longitude: -94.038, id: "Rum3nKUiH5kJK12kqz2i", zipCode: "50273", isSignedUp: true }, 
  { name: "Creston", latitude: 41.071, longitude: -94.384, id: "eb4vhNDupBnCI1LRDYsu", zipCode: "50801", isSignedUp: true }, 
  { name: "Afton", latitude: 41.029, longitude: -94.211, id: "mhj83g2gE9wjcNawnCSl", zipCode: "50830", isSignedUp: true }, 
  { name: "Corning", latitude: 41.003, longitude: -94.761, id: "yCFxoGnzH2CAZqDSQJg4", zipCode: "50841", isSignedUp: true },
  { name: "Villisca", latitude: 40.96, longitude: -94.99, id: "sNH6v5jr2ENxZEea0bgr", zipCode: "50864", isSignedUp: true },
  { name: "Red Oak", latitude: 41.006, longitude: -95.226, id: "51b5s5o3F0BPYHGjfJnf", zipCode: "51566", isSignedUp: true },
  { name: "Malvern", latitude: 40.999, longitude: -95.589, id: "zueH7y9kBc99JOylv6OA", zipCode: "51551", isSignedUp: true },
  { name: "Sidney", latitude: 40.763, longitude: -95.607, id: "OApI4W4dFMKZY0EXxXZN", zipCode: "51652", isSignedUp: true },
  { name: "Shenandoah", latitude: 40.75, longitude: -95.365, id: "mxtzH6tSCQw20PZKGCz6", zipCode: "51601", isSignedUp: true },
  { name: "Clarinda", latitude: 40.739, longitude: -95.036, id: "A2We5FFgi9PDKxIwdLrM", zipCode: "51632", isSignedUp: true },
  { name: "Lenox", latitude: 40.909, longitude: -94.523, id: "zxlJAtH4CTfdurkqg1Hf", zipCode: "50851", isSignedUp: true },
  { name: "Bedford ", latitude: 41.003, longitude: -94.761, id: "PbGcsYDmTchpIS6XSRu0", zipCode: "50833", isSignedUp: true },
  { name: "Mt. Ayr", latitude: 40.7, longitude: -94.226, id: "qxQrc8NUXm4A9ljGYTDr", zipCode: "50854", isSignedUp: true },
  { name: "Lamoni", latitude: 40.631, longitude: -93.949, id: "I078ljGcIAQ5iTyXncei", zipCode: "50140", isSignedUp: true },
  { name: "Murray", latitude: 41.0418, longitude: -93.9493, id: "wcsEAkZfB6iexlcyDMBN", zipCode: "50174", isSignedUp: true },
  { name: "Stanton", latitude: 40.9778, longitude: -95.1017, id: "f1PfKehjByAA13dGsGwJ", zipCode: "51573", isSignedUp: true },
  { name: "Massena", latitude: 41.25420, longitude: -94.76830, id: "xV02VcKHglARs3wPTn6b", zipCode: "50853", isSignedUp: true },
  { name: "Diagonal", latitude: 40.80940, longitude: -94.34220, id: "ObN9H2x7uHpcRSb1i78A", zipCode: "50845", isSignedUp: true },
  { name: "Webster City", latitude: 42.465, longitude: -93.821, id: "SZz0Z7bSa5zUftg0TiNq", zipCode: "50595", isSignedUp: true },
  { name: "Monticello", latitude: 42.221, longitude: -91.193, id: "CX8GxAv8cbSFXd8z24Yl", zipCode: "52310", isSignedUp: true },
  { name: "Glenwood", latitude: 41.0469, longitude: -95.7425, id: "l0lTUJuJUDz7c8ZHhU6p", zipCode: "51534", isSignedUp: true },
  { name: "Gray", latitude: 41.83940, longitude: -94.98300, id: "u3ovODyj79jBBNSp9BEw", zipCode: "00003", isSignedUp: true },
  { name: "Brayton", latitude: 41.54440, longitude: -94.92420, id: "P42tDGyhpV5IHTx5tj6w", zipCode: "50042", isSignedUp: true },
  { name: "Sac City", latitude: 42.4310, longitude: -94.9930, id: "7LhG4svhfwhesirjrLet" , zipCode: "50583", isSignedUp: true},
  {name: "Lake View", latitude: 42.3020, longitude: -95.0310, id: "gFTC8XfJ0DD0bDx0itj3", zipCode: "51450", isSignedUp: true},
  {name: "Wall Lake", latitude: 42.2570, longitude: -95.0930, id: "oUJiIClLopyWudNSVCVN", zipCode: "51466", isSignedUp: true},
  { name: "Odebolt", latitude: 42.316, longitude: -95.236, id: "xIZL9ffZ9Iu311hNuU6B", zipCode: "51458", isSignedUp: true },
  { name: "Auburn", latitude: 42.2510800, longitude: -94.877750, id: "cnHPXu0cq79xAQRK1JKI", zipCode: "51433", isSignedUp: true },
  { name: "Early", latitude: 42.4609700, longitude: -95.152170, id: "RTjVEuUdY3vVXabTUmQI", zipCode: "50535", isSignedUp: true },
  { name: "Lytton", latitude: 42.4222500, longitude: -94.860210, id: "Fi31GONcsatMRCiCwfNe", zipCode: "50561", isSignedUp: true },
  { name: "Nemaha", latitude: 42.5148900, longitude: -95.088750, id: "I4IwMUlwUHGu1M4BtrFZ", zipCode: "50567", isSignedUp: true },
  { name: "Schaller", latitude: 42.5005300, longitude: -95.293430, id: "GVcF1InlYyTJUXsuhE2p", zipCode: "51053", isSignedUp: true },
  { name: "Halbur", latitude: 42.006, longitude: -94.969, id: "gLq8dJQjeQmXd3Y9lori", zipCode: "51444", isSignedUp: true },
  { name: "Lake City", latitude: 42.267, longitude: -94.736, id: "VgqAh0lJ0MA6GZxyx2TM", zipCode: "51449", isSignedUp: true },
  { name: "Harlan", latitude: 41.333, longitude: -95.587, id: "xxnrNXCENgg3UxAX7kYU", zipCode: "51593", isSignedUp: true },
  ,{name: "Irwin", latitude: 41.7860, longitude: -95.2020, id: "nkPUL80xgDriTfa76vGX", zipCode: "51446", isSignedUp: true}
      
    ,{name: "Casey", latitude: 41.5140, longitude: -94.5210, id: "QYRE7Ya6UPxAbcu6VKOf", zipCode: "50048", isSignedUp: true}
    
    ,{name: "Westside", latitude: 42.0720, longitude: -95.1070, id: "8EGQUp68EwLCb6JduYfp", zipCode: "51467", isSignedUp: true}
    
  
];
  
  
  
  
  const upNextCities = [
    { name: "Brazil", latitude: 39.540510, longitude: -87.136010, id: "RG4hCqz8VtqmPdknHcNZ", zipCode: "47834", isSignedUp: true },
  { name: "Clay City", latitude: 39.258890, longitude: -87.131130, id: "ODvlHMVS9XinVAxmpsV9", zipCode: "47841", isSignedUp: true },
  { name: "Center Point", latitude: 39.406110, longitude: -87.055190, id: "XNL91ijxn4MkSDRLLV84", zipCode: "47840", isSignedUp: true },
  { name: "Carbon", latitude: 39.623010, longitude: -87.106230, id: "MWj4Csxm77HoQfQOs89r", zipCode: "47837", isSignedUp: true },
  { name: "Harmony", latitude: 39.536370, longitude: -87.070310, id: "M2EhGGia5aElCyo2sKym", zipCode: "47853", isSignedUp: true },
  { name: "Knightsville", latitude: 39.529800, longitude: -87.098600, id: "r1Q62nYQXwORJyJsBjVr", zipCode: "47857", isSignedUp: true },
  { name: "Staunton", latitude: 39.487480, longitude: -87.189540, id: "iVpwCiTAB9lUjLqJXyGR", zipCode: "47881", isSignedUp: true },

  { name: "Winslow", latitude: 35.0242, longitude: -110.6974, id: "gACFUjFB3bU7uz6PQtX0", zipCode: "86047", isSignedUp: true },
    { name: "Greene", latitude: 42.891, longitude: -92.808, id: "", zipCode: "50636", isSignedUp: true },
    
    
    {name: "Kirkman", latitude: 41.770, longitude: -95.6440, id: "GgY4m3rm4cnown69KQhy", zipCode: "51547", isSignedUp: true}
  ,{ name: "Lanesboro", latitude: 42.184, longitude: -94.691, id: "mWujUIXzPOho4u4kBPFO", zipCode: "51451", isSignedUp: true }
  ,{ name: "Mt. Carmel", latitude: 42.152, longitude: -94.908, id: "etrqvMf2Wph1aTkDwZZY", zipCode: "00000", isSignedUp: true },
  { name: "Roselle", latitude: 41.993, longitude: -94.914, id: "i4W6dyTVQIGwG4ybbrrY", zipCode: "00001", isSignedUp: true },
    
      
      
      
      
      
      ,{ name: "Rockwell City", latitude: 42.3960, longitude: -94.6330, id: "IkhOTJFTrVUrxnc6qQMl" , zipCode: "50579", isSignedUp: true}
      ,{ name: "Lidderdale", latitude: 42.1270, longitude: -94.7840, id: "26ysPFWen743Bced6aOx" , zipCode: "51452", isSignedUp: true}
      ,{ name: "Churdan", latitude: 42.1640, longitude: -94.5080, id: "YDhXEyr88qUSwIefkGyY" , zipCode: "50050", isSignedUp: true}
      ,{ name: "Paton", latitude: 42.1720, longitude: -94.2590, id: "xJl8TDu6er2Pc3aBwN4o" , zipCode: "50217", isSignedUp: true}
      ,{ name: "Grand Junction", latitude: 42.0370, longitude: -94.2190, id: "xoih6v5qFUdJ3E11PkQu" , zipCode: "50107", isSignedUp: true}
      
      
      
      
      
      
      
  ]
  
  
  
  
  const RemovedCities = [
    { name: "Panora", latitude: 41.702, longitude: -94.365, id: "Qr1ilZnxyIykt1CpLsnI", zipCode: "50216", isSignedUp: true },
    { name: "Dayton", latitude: 42.266, longitude: -94.052, id: "", zipCode: "50530" , isSignedUp: true },
  { name: "Stratford", latitude: 42.267, longitude: -93.927, id: "", zipCode: "50294", isSignedUp: true },{ name: "Grand Junction", latitude: 42.037, longitude: -94.219, id: "", zipCode: "50107", isSignedUp: true },
  
  
  { name: "Van Meter", latitude: 41.491, longitude: -93.945, id: "", zipCode: "50261", isSignedUp: true },{ name: "Mapleton", latitude: 42.165, longitude: -95.791, id: "", zipCode: "51034", isSignedUp: true },{ name: "Slater", latitude: 41.871, longitude: -93.675, id: "", zipCode: "50244", isSignedUp: true },
  { name: "Woodward", latitude: 41.855, longitude: -93.928, id: "", zipCode: "50276", isSignedUp: true },
  
  { name: "Earlham", latitude: 41.468, longitude: -94.125, id: "", zipCode: "50072", isSignedUp: true },{ name: "Granger", latitude: 41.76, longitude: -93.815, id: "", zipCode: "50109", isSignedUp: true },
  { name: "Woodbine", latitude: 41.743, longitude: -95.711, id: "", zipCode: "51579", isSignedUp: true },{ name: "Ogden", latitude: 42.046, longitude: -94.044, id: "", zipCode: "50212", isSignedUp: true },{ name: "Denison", latitude: 42.019, longitude: -95.354, id: "", zipCode: "51442", isSignedUp: true },
  
  
  { name: "Decorah", latitude: 43.346, longitude: -91.772, id: "", zipCode: "52101", isSignedUp: true },
  { name: "Perry", latitude: 41.847, longitude: -94.11, id: "", zipCode: "50220", isSignedUp: true },
  
  { name: "Polk City", latitude: 41.783, longitude: -93.714, id: "", zipCode: "50226", isSignedUp: true },
  { name: "Mount Vernon", latitude: 41.929, longitude: -91.437, id: "", zipCode: "52314", isSignedUp: true },
  { name: "Huxley", latitude: 41.893, longitude: -93.598, id: "", zipCode: "50124", isSignedUp: true },
  { name: "Madrid", latitude: 41.877, longitude: -93.799, id: "", zipCode: "50156", isSignedUp: true },
  { name: "Center Point", latitude: 42.194, longitude: -91.779, id: "", zipCode: "52213", isSignedUp: true },{ name: "Waukee", latitude: 41.602, longitude: -93.862, id: "", zipCode: "50263", isSignedUp: true },
    { name: "North Liberty", latitude: 41.756, longitude: -91.612, id: "", zipCode: "52317", isSignedUp: true },
    { name: "Altoona", latitude: 41.645, longitude: -93.466, id: "", zipCode: "50009", isSignedUp: true },
    { name: "Clive", latitude: 41.608, longitude: -93.781, id: "", zipCode: "50325", isSignedUp: true },
    { name: "Indianola", latitude: 41.354, longitude: -93.575, id: "", zipCode: "50125", isSignedUp: true },
    { name: "Newton", latitude: 41.702, longitude: -93.045, id: "", zipCode: "50208", isSignedUp: true },
    { name: "Boone", latitude: 42.075, longitude: -93.874, id: "", zipCode: "50036", isSignedUp: true },{ name: "Lake View", latitude: 42.302, longitude: -95.031, id: "", zipCode: "", isSignedUp: true },{ name: "Arnolds Park", latitude: 43.364, longitude: -95.129, id: "", zipCode: "", isSignedUp: true },
    { name: "Rockwell", latitude: 42.995, longitude: -93.202, id: "", zipCode: "", isSignedUp: true },
    { name: "Treynor", latitude: 41.231, longitude: -95.612, id: "", zipCode: "", isSignedUp: true },
    { name: "Okoboji", latitude: 43.388, longitude: -95.138, id: "", zipCode: "", isSignedUp: true },
    { name: "Johnston", latitude: 41.675, longitude: -93.719, id: "", zipCode: "", isSignedUp: true },
    { name: "Coralville", latitude: 41.688, longitude: -91.587, id: "", zipCode: "", isSignedUp: true },
    { name: "Grimes", latitude: 41.678, longitude: -93.794, id: "", zipCode: "", isSignedUp: true },
    { name: "Oskaloosa", latitude: 41.282, longitude: -92.655, id: "", zipCode: "", isSignedUp: true },
    { name: "Spencer", latitude: 43.15, longitude: -95.145, id: "", zipCode: "", isSignedUp: true },
    { name: "Storm Lake", latitude: 42.647, longitude: -95.181, id: "", zipCode: "", isSignedUp: true },
    { name: "Fort Madison", latitude: 40.638, longitude: -91.339, id: "", zipCode: "", isSignedUp: true },
    { name: "Norwalk", latitude: 41.471, longitude: -93.682, id: "", zipCode: "", isSignedUp: true },
    { name: "Keokuk", latitude: 40.41, longitude: -91.4, id: "", zipCode: "", isSignedUp: true },
    { name: "Pella", latitude: 41.411, longitude: -92.911, id: "", zipCode: "", isSignedUp: true },
    { name: "Fairfield", latitude: 41.017, longitude: -91.968, id: "", zipCode: "", isSignedUp: true },
    { name: "Waverly", latitude: 42.741, longitude: -92.46, id: "", zipCode: "", isSignedUp: true },
    { name: "Le Mars", latitude: 42.797, longitude: -96.172, id: "", zipCode: "", isSignedUp: true },
    { name: "Pleasant Hill", latitude: 41.588, longitude: -93.506, id: "", zipCode: "", isSignedUp: true },
    { name: "Grinnell", latitude: 41.686, longitude: -92.532, id: "", zipCode: "", isSignedUp: true },
    { name: "Mount Pleasant", latitude: 40.974, longitude: -91.572, id: "", zipCode: "", isSignedUp: true },
    { name: "Clear Lake", latitude: 43.138, longitude: -93.385, id: "", zipCode: "", isSignedUp: true },
    { name: "Sioux Center", latitude: 43.075, longitude: -96.19, id: "", zipCode: "", isSignedUp: true },
    { name: "Charles City", latitude: 43.073, longitude: -92.667, id: "", zipCode: "", isSignedUp: true },
    { name: "Washington", latitude: 41.3, longitude: -91.697, id: "", zipCode: "", isSignedUp: true },
    { name: "Hiawatha", latitude: 42.044, longitude: -91.681, id: "", zipCode: "", isSignedUp: true },
    { name: "Knoxville", latitude: 41.335, longitude: -93.099, id: "", zipCode: "", isSignedUp: true },
    { name: "Nevada", latitude: 42.026, longitude: -93.448, id: "", zipCode: "", isSignedUp: true },
    
    { name: "Eldridge", latitude: 41.659, longitude: -90.569, id: "", zipCode: "", isSignedUp: true },
    { name: "Orange City", latitude: 43.016, longitude: -96.062, id: "", zipCode: "", isSignedUp: true },
    { name: "Oelwein", latitude: 42.683, longitude: -91.922, id: "", zipCode: "", isSignedUp: true },
    { name: "Independence", latitude: 42.47, longitude: -91.893, id: "", zipCode: "", isSignedUp: true },
    { name: "Maquoketa", latitude: 42.088, longitude: -90.674, id: "", zipCode: "", isSignedUp: true },
    { name: "Estherville", latitude: 43.401, longitude: -94.815, id: "", zipCode: "", isSignedUp: true },
    { name: "Bondurant", latitude: 41.704, longitude: -93.462, id: "", zipCode: "", isSignedUp: true },
    { name: "Centerville", latitude: 40.73, longitude: -92.89, id: "", zipCode: "", isSignedUp: true },
    { name: "Anamosa", latitude: 42.107, longitude: -91.275, id: "", zipCode: "", isSignedUp: true },
    { name: "Algona", latitude: 43.074, longitude: -94.226, id: "", zipCode: "", isSignedUp: true },
    
    
    
    
    { name: "DeWitt", latitude: 41.826, longitude: -90.53, id: "", zipCode: "", isSignedUp: true },
    { name: "Sheldon", latitude: 43.185, longitude: -95.859, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Vinton and Iowa Falls",
      latitude: 42.172,
      longitude: -92.016,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Manchester", latitude: 42.484, longitude: -91.452, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Osceola and Spirit Lake",
      latitude: 41.031,
      longitude: -93.77,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Windsor Heights", latitude: 0, longitude: 0, id: "", zipCode: "", isSignedUp: true },
    { name: "Cherokee", latitude: 42.754, longitude: -95.549, id: "", zipCode: "", isSignedUp: true },
    { name: "Evansdale", latitude: 42.478, longitude: -92.283, id: "", zipCode: "", isSignedUp: true },
    { name: "Sergeant Bluff", latitude: 42.386, longitude: -96.342, id: "", zipCode: "", isSignedUp: true },
    { name: "Humboldt", latitude: 42.72, longitude: -94.225, id: "", zipCode: "", isSignedUp: true },
    { name: "Camanche", latitude: 41.781, longitude: -90.289, id: "", zipCode: "", isSignedUp: true },
    { name: "Adel", latitude: 41.613, longitude: -94.036, id: "", zipCode: "", isSignedUp: true },
    { name: "Hampton", latitude: 42.743, longitude: -93.215, id: "", zipCode: "", isSignedUp: true },
    
    { name: "Chariton", latitude: 41.029, longitude: -93.299, id: "", zipCode: "", isSignedUp: true },
    { name: "Carlisle", latitude: 41.491, longitude: -93.481, id: "", zipCode: "", isSignedUp: true },
    { name: "Dyersville", latitude: 42.491, longitude: -91.128, id: "", zipCode: "", isSignedUp: true },
    { name: "Forest City", latitude: 43.259, longitude: -93.656, id: "", zipCode: "", isSignedUp: true },
    { name: "Le Claire", latitude: 41.609, longitude: -90.36, id: "", zipCode: "", isSignedUp: true },
    
    { name: "Carter Lake", latitude: 41.293, longitude: -95.914, id: "", zipCode: "", isSignedUp: true },
    { name: "Cresco", latitude: 43.382, longitude: -92.103, id: "", zipCode: "", isSignedUp: true },
    { name: "Emmetsburg", latitude: 43.115, longitude: -94.693, id: "", zipCode: "", isSignedUp: true },
    { name: "Albia", latitude: 41.027, longitude: -92.798, id: "", zipCode: "", isSignedUp: true },
    { name: "West Liberty", latitude: 41.574, longitude: -91.266, id: "", zipCode: "", isSignedUp: true },
    { name: "Waukon", latitude: 43.266, longitude: -91.478, id: "", zipCode: "", isSignedUp: true },
    { name: "Rock Valley", latitude: 43.193, longitude: -96.324, id: "", zipCode: "", isSignedUp: true },
    { name: "Osage", latitude: 43.291, longitude: -92.821, id: "", zipCode: "", isSignedUp: true },
    { name: "Saylorville", latitude: 0, longitude: 0, id: "", zipCode: "", isSignedUp: true },
    { name: "New Hampton", latitude: 43.072, longitude: -92.323, id: "", zipCode: "", isSignedUp: true },
    { name: "Eagle Grove", latitude: 42.659, longitude: -93.907, id: "", zipCode: "", isSignedUp: true },
    { name: "Robins", latitude: 42.074, longitude: -91.663, id: "", zipCode: "", isSignedUp: true },
    { name: "Story City", latitude: 42.186, longitude: -93.599, id: "", zipCode: "", isSignedUp: true },
    { name: "Tipton", latitude: 41.751, longitude: -91.134, id: "", zipCode: "", isSignedUp: true },
    { name: "Williamsburg", latitude: 41.652, longitude: -92.014, id: "", zipCode: "", isSignedUp: true },
    { name: "Garner", latitude: 43.102, longitude: -93.61, id: "", zipCode: "", isSignedUp: true },
    { name: "Tiffin", latitude: 41.71, longitude: -91.67, id: "", zipCode: "", isSignedUp: true },
    { name: "Milford", latitude: 43.332, longitude: -95.163, id: "", zipCode: "", isSignedUp: true },
    { name: "West Burlington", latitude: 40.831, longitude: -91.178, id: "", zipCode: "", isSignedUp: true },
    { name: "Jesup", latitude: 42.453, longitude: -92.093, id: "", zipCode: "", isSignedUp: true },
    { name: "Wilton", latitude: 41.605, longitude: -91.006, id: "", zipCode: "", isSignedUp: true },
    { name: "Onawa", latitude: 42.027, longitude: -96.119, id: "", zipCode: "", isSignedUp: true },
    { name: "Tama", latitude: 41.962, longitude: -92.571, id: "", zipCode: "", isSignedUp: true },
    { name: "Clarion", latitude: 42.733, longitude: -93.735, id: "", zipCode: "", isSignedUp: true },
    { name: "Park View", latitude: 0, longitude: 0, id: "", zipCode: "", isSignedUp: true },
    { name: "Hawarden", latitude: 43.006, longitude: -96.471, id: "", zipCode: "", isSignedUp: true },
    { name: "Grundy Center", latitude: 42.361, longitude: -92.78, id: "", zipCode: "", isSignedUp: true },
    { name: "Eldora", latitude: 42.358, longitude: -93.098, id: "", zipCode: "", isSignedUp: true },
    { name: "Bloomfield", latitude: 40.73, longitude: -92.444, id: "", zipCode: "", isSignedUp: true },
    { name: "Missouri Valley", latitude: 41.558, longitude: -95.907, id: "", zipCode: "", isSignedUp: true },
    { name: "Sibley", latitude: 43.407, longitude: -95.739, id: "", zipCode: "", isSignedUp: true },
    { name: "Marengo", latitude: 41.79, longitude: -92.077, id: "", zipCode: "", isSignedUp: true },
    { name: "La Porte City", latitude: 42.325, longitude: -92.194, id: "", zipCode: "", isSignedUp: true },
    { name: "Fairfax", latitude: 41.912, longitude: -91.788, id: "", zipCode: "", isSignedUp: true },
    { name: "Solon", latitude: 41.81, longitude: -91.508, id: "", zipCode: "", isSignedUp: true },
    { name: "Belmond", latitude: 42.843, longitude: -93.619, id: "", zipCode: "", isSignedUp: true },
    { name: "West Union", latitude: 42.984, longitude: -91.818, id: "", zipCode: "", isSignedUp: true },
    { name: "Rock ", latitude: 43.43, longitude: -96.166, id: "", zipCode: "", isSignedUp: true },
    { name: "Postville", latitude: 43.104, longitude: -91.553, id: "", zipCode: "", isSignedUp: true },
    { name: "Kalona", latitude: 41.504, longitude: -91.71, id: "", zipCode: "", isSignedUp: true },
    
    { name: "Hudson", latitude: 42.377, longitude: -92.465, id: "", zipCode: "", isSignedUp: true },
    { name: "Belle Plaine", latitude: 41.888, longitude: -92.273, id: "", zipCode: "", isSignedUp: true },
    { name: "Columbus Junction", latitude: 41.266, longitude: -91.368, id: "", zipCode: "", isSignedUp: true },
    { name: "Mitchellville", latitude: 41.661, longitude: -93.353, id: "", zipCode: "", isSignedUp: true },
    { name: "Toledo", latitude: 42.025, longitude: -92.572, id: "", zipCode: "", isSignedUp: true },
    { name: "Lisbon", latitude: 41.909, longitude: -91.362, id: "", zipCode: "", isSignedUp: true },
    { name: "Bellevue", latitude: 42.25, longitude: -90.471, id: "", zipCode: "", isSignedUp: true },
    { name: "Northwood", latitude: 43.444, longitude: -93.24, id: "", zipCode: "", isSignedUp: true },
    { name: "Prairie City", latitude: 41.584, longitude: -93.242, id: "", zipCode: "", isSignedUp: true },
    { name: "Alta", latitude: 42.688, longitude: -95.465, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Ida Grove and Sac City",
      latitude: 42.431,
      longitude: -94.993,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Sumner", latitude: 42.847, longitude: -92.112, id: "", zipCode: "", isSignedUp: true },
    { name: "Lake Mills", latitude: 43.418, longitude: -93.525, id: "", zipCode: "", isSignedUp: true },
    { name: "Ely and Cascade", latitude: 42.29, longitude: -90.998, id: "", zipCode: "", isSignedUp: true },
    
    { name: "West Branch", latitude: 41.681, longitude: -91.334, id: "", zipCode: "", isSignedUp: true },
    { name: "Wapello", latitude: 41.172, longitude: -91.17, id: "", zipCode: "", isSignedUp: true },
    { name: "Hull", latitude: 43.192, longitude: -96.15, id: "", zipCode: "", isSignedUp: true },
    { name: "Leon", latitude: 40.737, longitude: -93.743, id: "", zipCode: "", isSignedUp: true },
    { name: "New London", latitude: 40.921, longitude: -91.4, id: "", zipCode: "", isSignedUp: true },
    { name: "Epworth", latitude: 42.451, longitude: -90.929, id: "", zipCode: "", isSignedUp: true },
    { name: "Sigourney", latitude: 41.329, longitude: -92.196, id: "", zipCode: "", isSignedUp: true },
    { name: "Britt and Colfax", latitude: 41.683, longitude: -93.236, id: "", zipCode: "", isSignedUp: true },
    { name: "Parkersburg", latitude: 42.576, longitude: -92.782, id: "", zipCode: "", isSignedUp: true },
    { name: "Atkins", latitude: 41.994, longitude: -91.868, id: "", zipCode: "", isSignedUp: true },
    { name: "Guttenberg", latitude: 42.765, longitude: -91.114, id: "", zipCode: "", isSignedUp: true },
    { name: "Ackley", latitude: 42.563, longitude: -93.05, id: "", zipCode: "", isSignedUp: true },
    { name: "Remsen", latitude: 42.786, longitude: -95.948, id: "", zipCode: "", isSignedUp: true },
    { name: "Peosta", latitude: 42.432, longitude: -90.816, id: "", zipCode: "", isSignedUp: true },
    { name: "Monroe", latitude: 41.528, longitude: -93.105, id: "", zipCode: "", isSignedUp: true },
    { name: "Farley", latitude: 42.442, longitude: -91.009, id: "", zipCode: "", isSignedUp: true },
    { name: "Dallas Center", latitude: 41.688, longitude: -93.949, id: "", zipCode: "", isSignedUp: true },
    { name: "Durant", latitude: 41.603, longitude: -90.91, id: "", zipCode: "", isSignedUp: true },
    { name: "Pleasantville", latitude: 41.382, longitude: -93.271, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Mediapolis",
      latitude: 41.009,
      longitude: -91.137,
      id: ""
  , zipCode: "", isSignedUp: true },
  
    { name: "Pocahontas", latitude: 42.725, longitude: -94.684, id: "", zipCode: "", isSignedUp: true },
    { name: "Hartley", latitude: 43.183, longitude: -95.468, id: "", zipCode: "", isSignedUp: true },
    { name: "Corydon", latitude: 40.757, longitude: -93.315, id: "", zipCode: "", isSignedUp: true },
    { name: "Denver", latitude: 42.671, longitude: -92.338, id: "", zipCode: "", isSignedUp: true },
    { name: "Moville", latitude: 42.475, longitude: -96.056, id: "", zipCode: "", isSignedUp: true },
    { name: "Traer", latitude: 42.194, longitude: -92.483, id: "", zipCode: "", isSignedUp: true },
    { name: "State Center", latitude: 42.01, longitude: -93.168, id: "", zipCode: "", isSignedUp: true },
    { name: "Logan", latitude: 41.643, longitude: -95.781, id: "", zipCode: "", isSignedUp: true },
    { name: "Monona and Kingsley", latitude: 43.061, longitude: -91.379, id: "", zipCode: "", isSignedUp: true },
    
    
    
    { name: "Brooklyn", latitude: 41.753, longitude: -92.45, id: "", zipCode: "", isSignedUp: true },
    { name: "Akron and ", latitude: 41.914, longitude: -95.069, id: "", zipCode: "", isSignedUp: true },
    { name: "Walcott", latitude: 41.617, longitude: -90.758, id: "", zipCode: "", isSignedUp: true },
    
    { name: "Nora Springs", latitude: 43.148, longitude: -93.005, id: "", zipCode: "", isSignedUp: true },
    { name: "Nashua", latitude: 42.953, longitude: -92.535, id: "", zipCode: "", isSignedUp: true },
    { name: "Reinbeck", latitude: 42.329, longitude: -92.607, id: "", zipCode: "", isSignedUp: true },
    { name: "Urbana", latitude: 42.222, longitude: -91.879, id: "", zipCode: "", isSignedUp: true },
    { name: "Blue Grass", latitude: 41.509, longitude: -90.754, id: "", zipCode: "", isSignedUp: true },
    { name: "Manly and Walford", latitude: 41.877, longitude: -91.835, id: "", zipCode: "", isSignedUp: true },
    { name: "Sanborn", latitude: 43.19, longitude: -95.654, id: "", zipCode: "", isSignedUp: true },
    { name: "Clarksville", latitude: 42.789, longitude: -92.666, id: "", zipCode: "", isSignedUp: true },
    { name: "Dysart", latitude: 42.163, longitude: -92.305, id: "", zipCode: "", isSignedUp: true },
    { name: "Avoca", latitude: 41.481, longitude: -95.339, id: "", zipCode: "", isSignedUp: true },
    { name: "Shell Rock", latitude: 42.71, longitude: -92.597, id: "", zipCode: "", isSignedUp: true },
    { name: "Montezuma", latitude: 41.581, longitude: -92.539, id: "", zipCode: "", isSignedUp: true },
    { name: "Elkader", latitude: 42.855, longitude: -91.409, id: "", zipCode: "", isSignedUp: true },
    { name: "Strawberry Point", latitude: 42.698, longitude: -91.515, id: "", zipCode: "", isSignedUp: true },
    { name: "Holstein", latitude: 42.496, longitude: -95.555, id: "", zipCode: "", isSignedUp: true },
    
    { name: "Central City", latitude: 42.199, longitude: -91.508, id: "", zipCode: "", isSignedUp: true },
    { name: "Wellman", latitude: 41.479, longitude: -91.854, id: "", zipCode: "", isSignedUp: true },
    { name: "Alton", latitude: 42.983, longitude: -95.996, id: "", zipCode: "", isSignedUp: true },
    { name: "Jewell Junction", latitude: 42.303, longitude: -93.652, id: "", zipCode: "", isSignedUp: true },
    { name: "Melcher-Dallas", latitude: 41.242, longitude: -93.173, id: "", zipCode: "", isSignedUp: true },
    { name: "Tabor", latitude: 40.886, longitude: -95.67, id: "", zipCode: "", isSignedUp: true },
    { name: "St. Ansgar", latitude: 43.403, longitude: -92.938, id: "", zipCode: "", isSignedUp: true },
    { name: "Lone Tree", latitude: 41.477, longitude: -91.433, id: "", zipCode: "", isSignedUp: true },
    { name: "Dike", latitude: 42.463, longitude: -92.662, id: "", zipCode: "", isSignedUp: true },
    { name: "Marcus", latitude: 42.793, longitude: -95.79, id: "", zipCode: "", isSignedUp: true },
    { name: "Roland", latitude: 42.168, longitude: -93.494, id: "", zipCode: "", isSignedUp: true },
    { name: "Laurens", latitude: 42.844, longitude: -94.843, id: "", zipCode: "", isSignedUp: true },
    { name: "Eddyville", latitude: 41.152, longitude: -92.642, id: "", zipCode: "", isSignedUp: true },
    { name: "Tripoli", latitude: 42.808, longitude: -92.264, id: "", zipCode: "", isSignedUp: true },
    { name: "University Heights", latitude: 0, longitude: 0, id: "", zipCode: "", isSignedUp: true },
    { name: "Lake Park", latitude: 43.438, longitude: -95.316, id: "", zipCode: "", isSignedUp: true },
    
    { name: "Sloan", latitude: 42.222, longitude: -96.246, id: "", zipCode: "", isSignedUp: true },
    { name: "Buffalo", latitude: 41.457, longitude: -90.733, id: "", zipCode: "", isSignedUp: true },
    { name: "Palo", latitude: 42.065, longitude: -91.801, id: "", zipCode: "", isSignedUp: true },
    { name: "Fairbank", latitude: 42.64, longitude: -92.07, id: "", zipCode: "", isSignedUp: true },
    { name: "George", latitude: 43.333, longitude: -96.0, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Fayette and New Sharon",
      latitude: 42.834,
      longitude: -91.798,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Fredericksburg", latitude: 42.963, longitude: -92.208, id: "", zipCode: "", isSignedUp: true },
    { name: "Hamburg", latitude: 40.622, longitude: -95.667, id: "", zipCode: "", isSignedUp: true },
    { name: "Princeton", latitude: 41.678, longitude: -90.373, id: "", zipCode: "", isSignedUp: true },
    {
      name: "North English and Allison",
      latitude: 42.748,
      longitude: -92.803,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Sheffield", latitude: 42.885, longitude: -93.221, id: "", zipCode: "", isSignedUp: true },
    { name: "Springville", latitude: 42.063, longitude: -91.441, id: "", zipCode: "", isSignedUp: true },
    
    { name: "Eldon", latitude: 40.919, longitude: -92.225, id: "", zipCode: "", isSignedUp: true },
    { name: "Paullina", latitude: 42.976, longitude: -95.658, id: "", zipCode: "", isSignedUp: true },
    { name: "Wayland", latitude: 41.141, longitude: -91.669, id: "", zipCode: "", isSignedUp: true },
    { name: "Conrad", latitude: 42.238, longitude: -92.907, id: "", zipCode: "", isSignedUp: true },
    { name: "Fruitland", latitude: 41.356, longitude: -91.13, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Danville and Winfield",
      latitude: 40.866,
      longitude: -91.335,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Beaverdale", latitude: 0, longitude: 0, id: "", zipCode: "", isSignedUp: true },
    { name: "Baxter", latitude: 41.821, longitude: -93.147, id: "", zipCode: "", isSignedUp: true },
    { name: "Keota", latitude: 41.341, longitude: -91.942, id: "", zipCode: "", isSignedUp: true },
    { name: "Calmar", latitude: 43.194, longitude: -91.896, id: "", zipCode: "", isSignedUp: true },
    { name: "Long Grove", latitude: 41.732, longitude: -90.533, id: "", zipCode: "", isSignedUp: true },
    { name: "Lawton", latitude: 42.497, longitude: -96.188, id: "", zipCode: "", isSignedUp: true },
    {
      name: "West Point and Riverside",
      latitude: 40.72,
      longitude: -91.473,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Aplington", latitude: 42.606, longitude: -92.899, id: "", zipCode: "", isSignedUp: true },
    { name: "Newhall", latitude: 41.996, longitude: -91.969, id: "", zipCode: "", isSignedUp: true },
    { name: "Buffalo Center", latitude: 43.392, longitude: -93.943, id: "", zipCode: "", isSignedUp: true },
    { name: "Mechanicsville", latitude: 41.9, longitude: -91.254, id: "", zipCode: "", isSignedUp: true },
    { name: "Gladbrook", latitude: 42.191, longitude: -92.708, id: "", zipCode: "", isSignedUp: true },
    { name: "De Soto", latitude: 41.531, longitude: -94.008, id: "", zipCode: "", isSignedUp: true },
    { name: "Preston", latitude: 42.052, longitude: -90.408, id: "", zipCode: "", isSignedUp: true },
    { name: "Elk Run Heights", latitude: 0, longitude: 0, id: "", zipCode: "", isSignedUp: true },
    { name: "Newell", latitude: 42.612, longitude: -95.0, id: "", zipCode: "", isSignedUp: true },
    { name: "Janesville", latitude: 42.646, longitude: -92.473, id: "", zipCode: "", isSignedUp: true },
    { name: "Gilbert", latitude: 42.111, longitude: -93.645, id: "", zipCode: "", isSignedUp: true },
    { name: "Aurelia", latitude: 42.711, longitude: -95.431, id: "", zipCode: "", isSignedUp: true },
    { name: "Armstrong", latitude: 43.399, longitude: -94.472, id: "", zipCode: "", isSignedUp: true },
    { name: "Dunlap and Gowrie", latitude: 41.857, longitude: -95.618, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Donnellson and Ireton",
      latitude: 40.675,
      longitude: -91.574,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Inwood", latitude: 43.31, longitude: -96.458, id: "", zipCode: "", isSignedUp: true },
    { name: "Le Grand and Neola", latitude: 42.005, longitude: -92.775, id: "", zipCode: "", isSignedUp: true },
    { name: "Underwood", latitude: 41.388, longitude: -95.688, id: "", zipCode: "", isSignedUp: true },
    { name: "Larchwood", latitude: 43.452, longitude: -96.443, id: "", zipCode: "", isSignedUp: true },
    { name: "Whiting", latitude: 42.137, longitude: -96.166, id: "", zipCode: "", isSignedUp: true },
    { name: "Shellsburg", latitude: 42.093, longitude: -91.884, id: "", zipCode: "", isSignedUp: true },
    { name: "Primghar and Swisher", latitude: 41.84, longitude: -91.686, id: "", zipCode: "", isSignedUp: true },
    { name: "Clarence and Colo", latitude: 41.888, longitude: -91.046, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Schleswig and Winthrop",
      latitude: 42.459,
      longitude: -91.712,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Keosauqua", latitude: 40.748, longitude: -91.961, id: "", zipCode: "", isSignedUp: true },
    
    { name: "Melbourne", latitude: 41.94, longitude: -93.071, id: "", zipCode: "", isSignedUp: true },
    { name: "Boyden", latitude: 43.195, longitude: -96.015, id: "", zipCode: "", isSignedUp: true },
    { name: "Oxford", latitude: 41.69, longitude: -91.762, id: "", zipCode: "", isSignedUp: true },
    { name: "Essex", latitude: 40.823, longitude: -95.27, id: "", zipCode: "", isSignedUp: true },
    { name: "Ossian", latitude: 43.13, longitude: -91.749, id: "", zipCode: "", isSignedUp: true },
    { name: "Merrill", latitude: 42.714, longitude: -96.303, id: "", zipCode: "", isSignedUp: true },
    { name: "Lowden", latitude: 41.865, longitude: -90.943, id: "", zipCode: "", isSignedUp: true },
    { name: "Van Horne", latitude: 42.011, longitude: -92.083, id: "", zipCode: "", isSignedUp: true },
    { name: "Sully", latitude: 41.571, longitude: -92.859, id: "", zipCode: "", isSignedUp: true },
    { name: "Lake Panorama", latitude: 0, longitude: 0, id: "", zipCode: "", isSignedUp: true },
    { name: "Morning Sun", latitude: 41.097, longitude: -91.279, id: "", zipCode: "", isSignedUp: true },
    { name: "Alden", latitude: 42.508, longitude: -93.401, id: "", zipCode: "", isSignedUp: true },
    { name: "Garnavillo", latitude: 0, longitude: 0, id: "", zipCode: "", isSignedUp: true },
    { name: "Victor", latitude: 41.716, longitude: -92.293, id: "", zipCode: "", isSignedUp: true },
    { name: "Earlville and Olin", latitude: 42.491, longitude: -91.263, id: "", zipCode: "", isSignedUp: true },
    { name: "Gilbertville", latitude: 42.417, longitude: -92.214, id: "", zipCode: "", isSignedUp: true },
    { name: "Maxwell", latitude: 41.866, longitude: -93.401, id: "", zipCode: "", isSignedUp: true },
    { name: "Hinton", latitude: 42.61, longitude: -96.239, id: "", zipCode: "", isSignedUp: true },
    { name: "Montrose", latitude: 40.547, longitude: -91.439, id: "", zipCode: "", isSignedUp: true },
    { name: "Riceville and Hills", latitude: 43.377, longitude: -92.548, id: "", zipCode: "", isSignedUp: true },
    { name: "Kanawha", latitude: 42.927, longitude: -93.808, id: "", zipCode: "", isSignedUp: true },
    { name: "Walker", latitude: 42.29, longitude: -91.775, id: "", zipCode: "", isSignedUp: true },
    { name: "Hazleton", latitude: 42.606, longitude: -91.912, id: "", zipCode: "", isSignedUp: true },
    { name: "McGregor", latitude: 43.019, longitude: -91.204, id: "", zipCode: "", isSignedUp: true },
    { name: "Ventura", latitude: 43.124, longitude: -93.483, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Elkhart and Wellsburg",
      latitude: 42.451,
      longitude: -92.915,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Dunkerton", latitude: 42.576, longitude: -92.166, id: "", zipCode: "", isSignedUp: true },
    { name: "Graettinger", latitude: 43.24, longitude: -94.741, id: "", zipCode: "", isSignedUp: true },
    { name: "Cambridge and Milo", latitude: 41.899, longitude: -93.531, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Dakota City and Schaller",
      latitude: 42.721,
      longitude: -94.202,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Sioux ", latitude: 42.907, longitude: -95.143, id: "", zipCode: "", isSignedUp: true },
    { name: "Correctionville", latitude: 42.471, longitude: -95.805, id: "", zipCode: "", isSignedUp: true },
    { name: "Rockford", latitude: 43.049, longitude: -92.955, id: "", zipCode: "", isSignedUp: true },
    { name: "What Cheer", latitude: 41.404, longitude: -92.354, id: "", zipCode: "", isSignedUp: true },
    { name: "Lansing", latitude: 43.359, longitude: -91.254, id: "", zipCode: "", isSignedUp: true },
    { name: "Middle Amana", latitude: 41.791, longitude: -91.915, id: "", zipCode: "", isSignedUp: true },
    { name: "Ruthven", latitude: 43.133, longitude: -94.9, id: "", zipCode: "", isSignedUp: true },
    { name: "Readlyn", latitude: 42.693, longitude: -92.229, id: "", zipCode: "", isSignedUp: true },
    { name: "Farmington", latitude: 40.655, longitude: -91.724, id: "", zipCode: "", isSignedUp: true },
    { name: "Hubbard", latitude: 42.309, longitude: -93.312, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Wall Lake and Battle Creek",
      latitude: 42.257,
      longitude: -95.093,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Fremont", latitude: 41.215, longitude: -92.439, id: "", zipCode: "", isSignedUp: true },
    { name: "Carson", latitude: 41.231, longitude: -95.404, id: "", zipCode: "", isSignedUp: true },
    { name: "Wheatland", latitude: 41.844, longitude: -90.861, id: "", zipCode: "", isSignedUp: true },
    { name: "St. Charles", latitude: 41.304, longitude: -93.797, id: "", zipCode: "", isSignedUp: true },
    { name: "Hospers", latitude: 43.073, longitude: -95.899, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Lakeside, Murray, and Walnut",
      latitude: 41.04,
      longitude: -93.955,
      id: ""
  , zipCode: "", isSignedUp: true },
  
    { name: "West Bend", latitude: 42.979, longitude: -94.465, id: "", zipCode: "", isSignedUp: true },
    { name: "Raymond", latitude: 42.468, longitude: -92.218, id: "", zipCode: "", isSignedUp: true },
    { name: "Blairstown", latitude: 41.911, longitude: -92.085, id: "", zipCode: "", isSignedUp: true },
    { name: "Bancroft", latitude: 43.296, longitude: -94.226, id: "", zipCode: "", isSignedUp: true },
    { name: "Doon", latitude: 43.292, longitude: -96.222, id: "", zipCode: "", isSignedUp: true },
    { name: "Lovilia", latitude: 41.121, longitude: -92.936, id: "", zipCode: "", isSignedUp: true },
    { name: "Elgin", latitude: 42.936, longitude: -91.628, id: "", zipCode: "", isSignedUp: true },
    { name: "Brighton and Coggon", latitude: 42.291, longitude: -91.54, id: "", zipCode: "", isSignedUp: true },
    { name: "Hartford", latitude: 41.457, longitude: -93.391, id: "", zipCode: "", isSignedUp: true },
    { name: "New Hartford", latitude: 42.568, longitude: -92.627, id: "", zipCode: "", isSignedUp: true },
    { name: "Moravia", latitude: 40.867, longitude: -92.844, id: "", zipCode: "", isSignedUp: true },
    { name: "Early", latitude: 42.454, longitude: -95.164, id: "", zipCode: "", isSignedUp: true },
    { name: "Washburn", latitude: 0, longitude: 0, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Redfield and Charter Oak",
      latitude: 42.083,
      longitude: -95.579,
      id: ""
  , zipCode: "", isSignedUp: true },
    {
      name: "Shueyville and Alburnett",
      latitude: 42.158,
      longitude: -91.639,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Sutherland", latitude: 42.996, longitude: -95.456, id: "", zipCode: "", isSignedUp: true },
    { name: "Anthon", latitude: 42.378, longitude: -95.908, id: "", zipCode: "", isSignedUp: true },
    { name: "Stanwood", latitude: 41.888, longitude: -91.148, id: "", zipCode: "", isSignedUp: true },
    { name: "Albert City", latitude: 42.768, longitude: -94.97, id: "", zipCode: "", isSignedUp: true },
    { name: "Rolfe", latitude: 42.842, longitude: -94.531, id: "", zipCode: "", isSignedUp: true },
    { name: "Shelby", latitude: 41.523, longitude: -95.465, id: "", zipCode: "", isSignedUp: true },
    { name: "Dexter", latitude: 41.488, longitude: -94.238, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Grand Mound and Richland",
      latitude: 41.836,
      longitude: -90.67,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Badger", latitude: 42.625, longitude: -94.139, id: "", zipCode: "", isSignedUp: true },
    { name: "Gilman", latitude: 41.88, longitude: -92.794, id: "", zipCode: "", isSignedUp: true },
    { name: "New Virginia", latitude: 41.193, longitude: -93.714, id: "", zipCode: "", isSignedUp: true },
    { name: "Dow City", latitude: 41.927, longitude: -95.498, id: "", zipCode: "", isSignedUp: true },
    { name: "Grandview", latitude: 41.277, longitude: -91.189, id: "", zipCode: "", isSignedUp: true },
    { name: "Dows and Stacyville", latitude: 42.654, longitude: -93.508, id: "", zipCode: "", isSignedUp: true },
    { name: "Norway", latitude: 41.9, longitude: -91.907, id: "", zipCode: "", isSignedUp: true },
    { name: "Garwin", latitude: 42.079, longitude: -92.708, id: "", zipCode: "", isSignedUp: true },
    { name: "Burt", latitude: 43.193, longitude: -94.195, id: "", zipCode: "", isSignedUp: true },
    { name: "Allerton", latitude: 40.688, longitude: -93.379, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Ellsworth and Maynard",
      latitude: 43.495,
      longitude: -95.907,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Zearing", latitude: 42.159, longitude: -93.297, id: "", zipCode: "", isSignedUp: true },
    { name: "Lime Springs", latitude: 43.419, longitude: -92.289, id: "", zipCode: "", isSignedUp: true },
    { name: "Truro", latitude: 41.205, longitude: -93.846, id: "", zipCode: "", isSignedUp: true },
    { name: "Titonka", latitude: 43.243, longitude: -94.043, id: "", zipCode: "", isSignedUp: true },
    { name: "Milton", latitude: 40.68, longitude: -92.162, id: "", zipCode: "", isSignedUp: true },
    { name: "Salem", latitude: 40.848, longitude: -91.617, id: "", zipCode: "", isSignedUp: true },
    { name: "Ainsworth", latitude: 41.32, longitude: -91.548, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Latimer, Moulton, and Plainfield",
      latitude: 42.855,
      longitude: -92.503,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Runnells", latitude: 41.536, longitude: -93.382, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Ocheyedan, Elliott, and Delmar",
      latitude: 41.967,
      longitude: -90.632,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Klemme", latitude: 43.002, longitude: -93.583, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Albion and Birmingham",
      latitude: 42.116,
      longitude: -93.004,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Edgewood and Elma", latitude: 42.678, longitude: -91.362, id: "", zipCode: "", isSignedUp: true },
    { name: "Fontanelle", latitude: 41.307, longitude: -94.557, id: "", zipCode: "", isSignedUp: true },
    { name: "Russell", latitude: 40.962, longitude: -93.184, id: "", zipCode: "", isSignedUp: true },
    { name: "Batavia", latitude: 41.026, longitude: -92.16, id: "", zipCode: "", isSignedUp: true },
    {
      name: "Hopkinton, Pomeroy, Kellogg, and Agency",
      latitude: 42.556,
      longitude: -94.683,
      id: ""
  , zipCode: "", isSignedUp: true },
    { name: "Minden", latitude: 41.426, longitude: -95.546, id: "", zipCode: "", isSignedUp: true },
    { name: "Everly", latitude: 43.193, longitude: -95.319, id: "", zipCode: "", isSignedUp: true },
    { name: "Seymour", latitude: 40.674, longitude: -93.128, id: "", zipCode: "", isSignedUp: true },
    { name: "Radcliffe", latitude: 42.303, longitude: -93.453, id: "", zipCode: "", isSignedUp: true },
    { name: "Coalville", latitude: 43.001, longitude: -91.661, id: "", zipCode: "", isSignedUp: true },
    { name: "Maharishi Vedic City", latitude: 0, longitude: 0, id: "", zipCode: "", isSignedUp: true },
    { name: "Beacon", latitude: 41.274, longitude: -92.681, id: "", zipCode: "", isSignedUp: true },
    { name: "Hedrick", latitude: 41.172, longitude: -92.285, id: "", zipCode: "", isSignedUp: true },
    { name: "Keystone", latitude: 42.006, longitude: -92.197, id: "", zipCode: "", isSignedUp: true },
    { name: "Quasqueton", latitude: 42.395, longitude: -91.759, id: "", zipCode: "", isSignedUp: true },
    { name: "Crescent", latitude: 41.362, longitude: -95.873, id: "", zipCode: "", isSignedUp: true },
    { name: "Swea City", latitude: 43.393, longitude: -94.315, id: "", zipCode: "", isSignedUp: true },
    { name: "Lamont", latitude: 42.612, longitude: -91.657, id: "", zipCode: "", isSignedUp: true },
    { name: "New Albin", latitude: 43.481, longitude: -91.307, id: "", zipCode: "", isSignedUp: true },
    { name: "Fonda", latitude: 42.588, longitude: -94.842, id: "", zipCode: "", isSignedUp: true },
    { name: "Goldfield", latitude: 42.765, longitude: -93.941, id: "", isSignedUp: true }
  ];
  
  export default cities;
  
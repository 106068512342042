export default function CalculateLatLongDistance(
    cityLat,
    cityLong,
    userLat,
    userLong
) {
    let mToMiles = 0.000621371;
    const a = (userLat * Math.PI) / 180;
    const b = (cityLat * Math.PI) / 180;
    const radChange = ((cityLong - userLong) * Math.PI) / 180;
    const R = 6371e3;
    const d =
        Math.acos(
            Math.sin(a) * Math.sin(b) +
                Math.cos(a) * Math.cos(b) * Math.cos(radChange)
        ) * R;
    let distanceInMiles = mToMiles * d;
    return distanceInMiles;
}

import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";

const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3)
        }
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            backgroundColor: theme.palette.background.paper,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
        }
    }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
        minWidth: "100px"
    }
}));

export default function TimeSelector({
    hour,
    minutes,
    ampm,
    handleChangeHour,
    handleChangeMinutes,
    handleChangeAMPM
}) {
    const classes = useStyles();

    return (
        <div>
            <FormControl className={classes.margin}>
                <InputLabel id="demo-customized-select-label">Hour</InputLabel>
                <NativeSelect
                    id="demo-customized-select-native"
                    value={hour}
                    onChange={handleChangeHour}
                    input={<BootstrapInput />}
                >
                    <option value={"01"}>01</option>
                    <option value={"02"}>02</option>
                    <option value={"03"}>03</option>
                    <option value={"04"}>04</option>
                    <option value={"05"}>05</option>
                    <option value={"06"}>06</option>
                    <option value={"07"}>07</option>
                    <option value={"08"}>08</option>
                    <option value={"09"}>09</option>
                    <option value={"10"}>10</option>
                    <option value={"11"}>11</option>
                    <option value={"12"}>12</option>
                </NativeSelect>
            </FormControl>
            <FormControl className={classes.margin}>
                <InputLabel id="demo-customized-select-label">
                    Minute
                </InputLabel>
                <NativeSelect
                    id="demo-customized-select-native"
                    value={minutes}
                    onChange={handleChangeMinutes}
                    input={<BootstrapInput />}
                >
                    <option value={"00"}>00</option>
                    <option value={"15"}>15</option>
                    <option value={"30"}>30</option>
                    <option value={"45"}>45</option>
                </NativeSelect>
            </FormControl>
            <FormControl className={classes.margin}>
                <InputLabel htmlFor="demo-customized-select-native">
                    Label
                </InputLabel>
                <NativeSelect
                    id="demo-customized-select-native"
                    value={ampm}
                    onChange={handleChangeAMPM}
                    input={<BootstrapInput />}
                >
                    <option value={"AM"}>AM</option>
                    <option value={"PM"}>PM</option>
                </NativeSelect>
            </FormControl>
        </div>
    );
}

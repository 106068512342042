import db from "../firebase";

const CreateNewDbUserLog = (uid) => {
    var dbUserLog = db
        .collection("UserLogs")
        .doc(uid)
        .set({
            uid: uid,
            history: []
        });
    return dbUserLog;
};

export default CreateNewDbUserLog;

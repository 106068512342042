import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

export default function CreateEventSuccessModal({ open, handleClose, party }) {
    const classes = useStyles();
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">
                            You have submitted [{party.title}] for review. Once
                            approved, users will start seeing it!
                        </h2>

                        <Button
                            className="signInButton"
                            size="small"
                            color="primary"
                            id="googleButton"
                            style={{ width: "100%", margin: ".5rem" }}
                            onClick={() => handleClose()}
                        >
                            Ok
                        </Button>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

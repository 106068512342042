import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import OpeArt from "./opeArtNLogo";

class NoEventsInCityCard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Card className="noCard">
                <CardContent className="bottomSignCard">
                    <OpeArt></OpeArt>
                    <p>
                        Ope! There are no events going on in this town. You can
                        create an event with the button below the city. It will
                        show up after its been approved.
                    </p>
                    <br></br>
                </CardContent>
            </Card>
        );
    }
}

export default NoEventsInCityCard;

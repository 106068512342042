import React, { Component } from "react";
import ApprovalPartyCard from "./approvalCard";
import { DateSorter } from "../TwoZeroFunctions/dateSorter";

class ApprovedApprovalCardList extends Component {
    render() {
        let approvedEvents = this.props.adminPartyList.filter((party) => {
            if (party.approvalPhase === "3") {
                return party;
            }
        });
        let sortedApprovedEvents = DateSorter(approvedEvents);
        return (
            <div style={{ maxHeight: "57.5vh", overflowY: "scroll" }}>
                {sortedApprovedEvents.map((party) => (
                    <ApprovalPartyCard
                        handleGoToApprovalEditCard={
                            this.props.handleGoToApprovalEditCard
                        }
                        party={party}
                        key={party.uid}
                        handleApprovalCheckClick={
                            this.props.handleApprovalCheckClick
                        }
                        handleApprovalXClick={this.props.handleApprovalXClick}
                        deleteFromAdminPartyList={
                            this.props.deleteFromAdminPartyList
                        }
                    />
                ))}
            </div>
        );
    }
}

export default ApprovedApprovalCardList;

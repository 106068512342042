import React from "react";
import PartyCard from "./partyCard";
import CalculateLatLongDistance from "../TwoZeroFunctions/calculateLatLongDistance";
import NoPartiesCard from "./noPartiesCard";

export default function PartyCardList(props) {
    let queryList = props.queryList;

    let citiesInRadius = queryList.filter((city) => {
        let distance = CalculateLatLongDistance(
            city.latitude,
            city.longitude,
            props.latitude,
            props.longitude
        );
        if (props.userSettingsDoc) {
            return distance < props.userSettingsDoc.radius;
        } else {
            return true;
        }
    });

    let citiesInRadiusMap = {};

    citiesInRadius.forEach((city) => {
        citiesInRadiusMap[city.name] = true;
    });

    let filteredPartyList = props.partyList
        .filter((party) => {
            if (props.partyTypeFilterO[party.type]) {
                return party;
            }
        })
        .filter((party) => {
            if (party.approved) {
                return party;
            }
        })
        .filter((party) => {
            if (props.dayFilter === "0") {
                return party;
            } else if (props.dayFilter === "") {
                return party;
            } else {
                if (party.partyDate.day === props.dayFilter) {
                    return party;
                }
            }
        })
        .filter((party) => {
            if (props.monthFilter === "0") {
                return party;
            } else if (props.monthFilter === "") {
                return party;
            } else {
                if (party.partyDate.month === props.monthFilter) {
                    return party;
                }
            }
        })
        .filter((party) => {
            let isHidden = props.checkHiddenIncludes(party);
            if (!isHidden) {
                return party;
            }
        })
        .filter((party) => {
            return citiesInRadiusMap[party.cityName];
        });
    // .filter((party) => {
    //     if (
    //         party.title
    //             .toLowerCase()
    //             .includes(
    //                 props.searchFilter.toLowerCase()
    //             )
    //     ) {
    //         return party;
    //     }
    // })
    return (
        <div className="partyCardList">
            {filteredPartyList.length > 0 ? (
                filteredPartyList.map((party) => (
                    <PartyCard
                        party={party}
                        key={party.uid}
                        page={props.page}
                        userSettingsDoc={props.userSettingsDoc}
                        handlePartyCardCityButton={
                            props.handlePartyCardCityButton
                        }
                        checkFavoritesIncludes={props.checkFavoritesIncludes}
                        checkHiddenIncludes={props.checkHiddenIncludes}
                        handleFavoriteClick={props.handleFavoriteClick}
                        handleHiddenClick={props.handleHiddenClick}
                    />
                ))
            ) : (
                <NoPartiesCard></NoPartiesCard>
            )}
        </div>
    );
}

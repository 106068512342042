import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import OpeArt from "./opeArtNLogo";

export default function SignInOrUpCard(props) {
    return (
        <Card className="signInOrUpCard">
            <CardContent className="bottomSignCard">
                <OpeArt></OpeArt>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                        className="signInButton"
                        size="large"
                        color="primary"
                        id="signUpButton"
                        onClick={() => props.changeScreen("signIn")}
                    >
                        I Have An Account
                    </Button>

                    <Button
                        className="signInButton"
                        size="large"
                        color="primary"
                        id="signUpButton"
                        onClick={() => props.changeScreen("signUp")}
                    >
                        Create An Account
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
}

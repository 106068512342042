import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    outerDiv: {
        backgroundColor: "white",
        marginBottom: 25,
        borderRadius: 5,
        width: "80%",
        height: 150,
        display: "flex",
        flexDirection: "column"
    },
    titleSkeleton: {
        alignSelf: "center",
        justifySelf: "center",
        width: "30%",
        marginBottom: 5,
        marginTop: 30,
        height: 35,
        borderRadius: 4
    },
    buttonSkeleton: {
        alignSelf: "center",
        justifySelf: "center",
        width: "30%",
        height: 35,
        borderRadius: 4
    }
}));

export default function SkeletonLoaderCityCard() {
    const classes = useStyles();
    return (
        <div className={classes.outerDiv}>
            <Skeleton variant="rect" className={classes.titleSkeleton} />
            <Skeleton variant="rect" className={classes.buttonSkeleton} />
        </div>
    );
}

import db from "../firebase";

const HandleEditPartyFormSave = (
    title,
    date,
    address,
    time,
    smallDescription,
    fullDescription,
    uid,
    dateObject,
    link,
    timeSegments
) => {
    try {
        console.log("IN THE TRY");
        db.collection("Parties")
            .doc(uid)
            .update({
                title,
                partyDate: date,
                address,
                time,
                smallDescription,
                fullDescription,
                dateObject,
                link,
                timeSegments
            })
            .then(() => {})
            .catch((error) => {
                console.error("error: ", error);
            });
    } catch (error) {
        console.log("CATCH");
        console.log("UPDATE ERROR", error);
        let docRef = db.collection("Parties").doc(uid);

        docRef.update({ timeSegments: timeSegments });
    }
};

export default HandleEditPartyFormSave;

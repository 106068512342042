const calculatePartyDates = (eventDateObjects) => {
    let partyDates = eventDateObjects.map((dateObject) => {
        let day = dateObject.getDate();
        let month = dateObject.getMonth();
        let year = dateObject.getYear();
        return { day, month: month + 1, year: year + 1900 };
    });

    return partyDates;
};

const GetFirstDayOfMonth = (dayAsNumber, month, year, dayOfMonthAsNumber) => {
    const date = new Date(year, month, 1);
    const daysToAddToFirstWeek = dayOfMonthAsNumber * 7;
    date.setDate(
        date.getDate() +
            ((7 + dayAsNumber - date.getDay()) % 7) +
            daysToAddToFirstWeek
    );
    return date;
};
const GetLastDayOfMonth = (
    dayAsNumber,
    month,
    year,
    dayOfMonthAsNumber,
    eventDateObject
) => {
    const daysToSubtractFromFirstWeek = 7;

    let dates = [];
    let date = new Date(year + 1, 1, 1);
    for (let i = 0; i < 13; i++) {
        date.setDate(
            date.getDate() +
                ((7 + dayAsNumber - date.getDay()) % 7) -
                daysToSubtractFromFirstWeek
        );
        dates = [...dates, date];
        date = new Date(year, 12 - i, 1);
    }

    let filteredDates = dates.filter((date) => {
        return date.getTime() >= eventDateObject.getTime();
    });

    return filteredDates;
};

const calculateRecurringDateObjects = (
    year,
    month,
    day,
    recurringFrequency,
    recurringUnits,
    dayOfWeek,
    dayOfMonth
) => {
    year = parseInt(year);
    month = parseInt(month);
    day = parseInt(day);
    const daysInAWeek = 7;
    let eventDateObject = new Date(year, month - 1, day);
    let eventDateObjects = [eventDateObject];
    const endOfYear = new Date(year + 1, 0, 0);
    const dayOfMonthAsNumber = parseInt(dayOfMonth);

    if (recurringUnits === "weeks") {
        while (eventDateObject.getTime() < endOfYear.getTime()) {
            let daysToAdd = daysInAWeek * recurringFrequency;
            let dayInMilliseconds = 86400000;
            let daysToAddInMilliseconds = dayInMilliseconds * daysToAdd;
            eventDateObject = new Date(
                eventDateObject.getTime() + daysToAddInMilliseconds
            );

            eventDateObjects = [...eventDateObjects, eventDateObject];
        }
    } else if (recurringUnits === "month") {
        while (eventDateObject.getTime() < endOfYear.getTime()) {
            let daysOfTheWeek = {
                Sunday: 0,
                Monday: 1,
                Tuesday: 2,
                Wednesday: 3,
                Thursday: 4,
                Friday: 5,
                Saturday: 6,
            };

            let dayAsNumber = daysOfTheWeek[dayOfWeek];
            if (dayOfMonth === "5") {
                let allEventDateObjects = GetLastDayOfMonth(
                    dayAsNumber,
                    eventDateObject.getMonth(),
                    eventDateObject.getFullYear(),
                    dayOfMonthAsNumber,
                    eventDateObject
                );

                eventDateObject = allEventDateObjects[0];
                eventDateObjects = [...allEventDateObjects];
            } else {
                eventDateObject = GetFirstDayOfMonth(
                    dayAsNumber,
                    eventDateObject.getMonth() + 1,
                    eventDateObject.getFullYear(),
                    dayOfMonthAsNumber
                );

                eventDateObjects = [...eventDateObjects, eventDateObject];
            }
        }
    } else if (recurringUnits === "year") {
        //FOR YEARS
        let oneCycle = parseInt(recurringFrequency);
        let twoCycle = parseInt(recurringFrequency) * 2;
        eventDateObjects = [
            eventDateObject,
            new Date(year + oneCycle, month - 1, day),
            new Date(year + twoCycle, month - 1, day),
        ];
    }
    return calculatePartyDates(eventDateObjects);
};

export default calculateRecurringDateObjects;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "50%"
        }
    }
}));

export default function MultilineTextFields(props) {
    const classes = useStyles();

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <div>
                <TextField
                    id="outlined-multiline-static"
                    label={props.label}
                    multiline
                    rows={props.name === "Small Description" ? 4 : 8}
                    placeholder={props.placeholder}
                    variant="outlined"
                    onChange={props.onChange}
                    name={props.name}
                    value={props.value}
                />
            </div>
        </form>
    );
}

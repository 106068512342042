import db from "../firebase";
import * as Sentry from "@sentry/react";

const GetUserLogs = (uid) => {
    return db
        .collection("UserLogs")
        .doc(uid)
        .get()
        .then((doc) => {
            let userLogs = doc.data();
            return userLogs;
        })
        .catch((error) => {
            Sentry.captureException(error);
        });
};

export default GetUserLogs;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block"
        }
    },

    search: {
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit"
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch"
        }
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    }
}));

export default function NewDashboard({
    changeScreen,
    queryList,
    clickSearchResultCity
}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={() => changeScreen("")}>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <i class="fas fa-home"></i>
                </IconButton>
                <p>Area Events</p>
            </MenuItem>
            <MenuItem onClick={() => changeScreen("citiesInRadius")}>
                <IconButton
                    aria-label="show 11 new notifications"
                    color="inherit"
                >
                    <i class="fas fa-city"></i>
                </IconButton>
                <p>Cities In Radius</p>
            </MenuItem>
            <MenuItem onClick={() => changeScreen("userScreen")}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <i class="fas fa-user"></i>
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.grow}>
            <AppBar position="static">
                <Toolbar>
                    <Typography
                        className={classes.title}
                        style={{
                            fontFamily: "Rubik",
                            flex: 1,
                            textAlign: "left",
                            cursor: "pointer"
                        }}
                        variant="h6"
                        noWrap
                        onClick={() => changeScreen("")}
                    >
                        Neighbored
                    </Typography>

                    <div className={classes.search} style={{ flex: 4 }}>
                        <Autocomplete
                            freeSolo
                            className={classes.search}
                            id="free-solo-2-demo"
                            disableClearable
                            onInputChange={(event, newInputValue) => {
                                console.log("newInputValue");
                            }}
                            options={queryList.map((option) => option.name)}
                            renderOption={(option) => (
                                <span
                                    style={{ cursor: "pointer", width: "100%" }}
                                    onClick={() => {
                                        clickSearchResultCity(option);
                                    }}
                                >
                                    {option}
                                </span>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search a City"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: "search"
                                    }}
                                />
                            )}
                        />
                    </div>

                    <div
                        className={classes.sectionDesktop}
                        style={{ flex: 1, justifyContent: "flex-end" }}
                    >
                        <IconButton
                            aria-label="show 4 new mails"
                            color="inherit"
                            onClick={() => changeScreen("")}
                        >
                            <i class="fas fa-home"></i>
                        </IconButton>
                        <IconButton
                            aria-label="show 17 new notifications"
                            color="inherit"
                            onClick={() => changeScreen("citiesInRadius")}
                        >
                            <i class="fas fa-city"></i>
                        </IconButton>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={() => changeScreen("userScreen")}
                            color="inherit"
                        >
                            <i class="fas fa-user"></i>
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <i class="fas fa-ellipsis-v"></i>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
}

import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    outerDiv: {
        backgroundColor: "white",
        marginBottom: 25,
        borderRadius: 5,
        width: "85%",
        height: 215
    },
    hideDiv: {
        justifyContent: "flex-end",
        display: "flex",
        flexDirection: "row"
    },
    hideSkeleton: {
        borderRadius: 25,
        marginBottom: 20,
        width: "2.5%",
        alignContent: "flex-end",
        alignItems: "flex-end",
        alignSelf: "flex-end",
        justifySelf: "flex-end"
    },
    titleSkeleton: {
        borderRadius: 4,
        height: 30,
        width: "40%",
        marginBottom: 3,
        alignContent: "center",
        alignSelf: "center"
    },
    detailsSkeleton: {
        borderRadius: 4,
        width: "40%",
        margin: 1,
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center"
    },
    citySkeleton: {
        borderRadius: 100,
        width: "5%"
    },
    favoriteSkeleton: {
        borderRadius: 50,
        width: "5%"
    },
    extraInfoSkeleton: {
        borderRadius: 50,

        width: "5%",
        marginLeft: "auto"
    },
    topOfCard: {
        padding: "16px",
        display: "flex",
        flexDirection: "column"
    },
    bottomOfCard: {
        padding: 10,
        display: "flex",
        flexDirection: "column"
    },
    buttonsDiv: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        height: "10%"
    }
}));

export default function SkeletonLoaderPartyCard() {
    const classes = useStyles();
    return (
        <div className={classes.outerDiv}>
            <div className={classes.topOfCard}>
                <div className={classes.hideDiv}>
                    <Skeleton
                        variant="circular"
                        width={30}
                        height={30}
                        className={classes.hideSkeleton}
                    />
                </div>
                <Skeleton variant="circle" className={classes.titleSkeleton} />
                <Skeleton variant="rect" className={classes.detailsSkeleton} />
                <Skeleton variant="rect" className={classes.detailsSkeleton} />
            </div>
            <div className={classes.bottomOfCard}>
                <div className={classes.buttonsDiv}>
                    <Skeleton
                        variant="circular"
                        width={30}
                        height={30}
                        className={classes.citySkeleton}
                    />

                    <Skeleton
                        variant="circular"
                        width={30}
                        height={30}
                        className={classes.favoriteSkeleton}
                    />
                    <Skeleton
                        variant="circular"
                        width={30}
                        height={30}
                        className={classes.favoriteSkeleton}
                    />

                    <Skeleton
                        variant="circular"
                        width={30}
                        height={30}
                        className={classes.extraInfoSkeleton}
                    />
                </div>
            </div>
        </div>
    );
}

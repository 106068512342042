import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import SkeletonLoaderPartyCard from "./skeletonLoaderPartyCard";

const useStyles = makeStyles((theme) => ({}));

export default function SkeletonLoaderPartyCards() {
    const classes = useStyles();
    return (
        <div className="partySkeletonCardList">
            <SkeletonLoaderPartyCard></SkeletonLoaderPartyCard>
            <SkeletonLoaderPartyCard></SkeletonLoaderPartyCard>
            <SkeletonLoaderPartyCard></SkeletonLoaderPartyCard>
            <SkeletonLoaderPartyCard></SkeletonLoaderPartyCard>
            <SkeletonLoaderPartyCard></SkeletonLoaderPartyCard>
            <SkeletonLoaderPartyCard></SkeletonLoaderPartyCard>
        </div>
    );
}

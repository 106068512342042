import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import OpeArt from "./opeArtNLogo";

class NoCitySignedUpCard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Card className="noCard">
                <CardContent className="bottomSignCard">
                    <OpeArt></OpeArt>
                    <p>
                        Ope! This city is not signed up for Neighbored. Send us
                        an email if you'd like this city on Neighbored!
                        neighboredllc@gmail.com
                    </p>
                    <br></br>
                </CardContent>
            </Card>
        );
    }
}

export default NoCitySignedUpCard;

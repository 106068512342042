import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import DeletePartyModal from "../components/deletePartyModal";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "85%"
    },
    media: {
        height: 0,
        paddingTop: "56.25%" // 16:9
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    },
    avatar: {
        backgroundColor: red[500]
    },
    button: {
        backgroundColor: "#264D26",
        marginTop: 5,
        alignSelf: "center",
        width: "35%",
        textAlign: "center",
        alignContent: "center",
        alignItems: "center",
        color: "white",
        textDecoration: "none",
        marginBottom: "2.5%"
    },
    link: {
        alignSelf: "center",
        justifySelf: "center",
        textDecoration: "none"
    },
    hiddenButton: {
        marginBottom: -10
    }
}));

export default function RecipeReviewCard(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    let hourEnd = "";
    let minutesEnd = "";
    let ampmEnd = "";
    let timeEndString = null;
    if (props.party.timeSegments) {
        hourEnd = props.party.timeSegments.hourEnd;
        minutesEnd = props.party.timeSegments.minutesEnd;
        ampmEnd = props.party.timeSegments.ampmEnd;
        timeEndString = props.party.timeSegments.hourEnd
            ? " - " + hourEnd + ":" + minutesEnd + ampmEnd
            : null;
    }

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Card
            className={classes.root}
            // style={{
            //   backgroundColor: handleColorChoice(props)
            // }}
        >
            <CardContent>
                <CardActions
                    style={{
                        margin: 0,
                        padding: 0,
                        justifyContent: "flex-end"
                    }}
                >
                    <IconButton
                        aria-label="city homepage"
                        className={classes.hiddenButton}
                        onClick={() => {
                            console.log("delete");
                            handleOpen();
                        }}
                    >
                        <i class="fas fa-times"></i>
                    </IconButton>
                </CardActions>
                <Typography>{props.party.title}</Typography>

                <Typography variant="body2">
                    City: {props.party.cityName}
                    <br></br>
                    Date: {props.party.partyDate.month}
                    {"/"}
                    {props.party.partyDate.day}
                    {"/"}
                    {props.party.partyDate.year}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {props.party.smallDescription}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton
                    aria-label="approve"
                    onClick={() => {
                        props.handleApprovalCheckClick(props.party);
                    }}
                >
                    <i class="fas fa-check-circle"></i>
                </IconButton>
                <IconButton
                    aria-label="deny"
                    onClick={() => {
                        props.handleApprovalXClick(props.party);
                    }}
                >
                    <i class="fas fa-times-circle"></i>
                </IconButton>
                <IconButton
                    onClick={() => {
                        props.handleGoToApprovalEditCard(props.party);
                    }}
                >
                    <i class="fas fa-edit"></i>
                </IconButton>

                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <i class="fas fa-arrow-down"></i>
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="body2">
                        Time: {props.party.time} {timeEndString}
                    </Typography>

                    <Typography variant="body2">
                        Address: {props.party.address}
                    </Typography>

                    <Typography variant="body2">
                        {props.party.fullDescription}
                    </Typography>
                </CardContent>
                {props.party.link === "" ||
                props.party.link === undefined ? null : (
                    <a
                        href={props.party.link}
                        target="_blank"
                        className={classes.link}
                    >
                        <Button primary className={classes.button}>
                            Event Link
                        </Button>
                    </a>
                )}
            </Collapse>
            <DeletePartyModal
                open={open}
                handleClose={handleClose}
                deleteParty={props.party}
                deleteFromAdminPartyList={props.deleteFromAdminPartyList}
            ></DeletePartyModal>
        </Card>
    );
}

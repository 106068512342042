import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import NeighboredCircle from "../images/NeighboredLogoV2.png";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ForgotPasswordModal from "./forgotPasswordModal";

class SignInCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            successfulSignIn: true,
            googleSignedIn: false,
            facebookSignedIn: false,
            successBanner: "",
            showPassword: false,
            open: false
        };
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    handleSubmit = (e) => {
        e.preventDefault();
    };

    render() {
        return (
            <Card className="signInCard" onSubmit={this.handleSubmit}>
                <CardContent className="bottomSignCard">
                    <CardMedia
                        component="img"
                        alt="NeighboredCircleLogoV2"
                        width="20px"
                        height="auto"
                        className="signUpCardImage"
                        image={NeighboredCircle}
                        title="NeighboredCircleLogoV2"
                    />
                    <h3 className="signUpText">Sign In</h3>
                    {!this.state.successfulSignIn && (
                        <h3>Either Your Email or Password Don't Match Ours</h3>
                    )}

                    <p
                        style={{ color: "#264d26", cursor: "pointer" }}
                        onClick={() => this.handleOpen()}
                    >
                        Forgot Password?
                    </p>

                    <OutlinedInput
                        id="outlined-adornment-email"
                        className="formFieldSignIn passwordTextField"
                        value={this.state.email}
                        placeholder="Email"
                        name="email"
                        onChange={(event) => {
                            this.handleChange(event);
                        }}
                    />

                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={this.state.showPassword ? "text" : "password"}
                        className="formFieldSignIn passwordTextField"
                        value={this.state.password}
                        placeholder="Password"
                        name="password"
                        onChange={(event) => {
                            this.handleChange(event);
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                    edge="end"
                                >
                                    {this.state.showPassword ? (
                                        <i class="fas fa-eye-slash"></i>
                                    ) : (
                                        <i class="fas fa-eye"></i>
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <h4>{this.state.successBanner}</h4>

                    <div className="signInButtonDiv">
                        <Button
                            className="signInButton"
                            size="large"
                            color="primary"
                            id="signInButton"
                            onClick={() => {
                                this.props.signIn(
                                    this.state.email,
                                    this.state.password
                                );
                            }}
                        >
                            Sign In
                        </Button>
                        {/* <Button
                            className="signInButton"
                            size="small"
                            color="primary"
                            id="facebookButton"
                            onClick={() => {
                                var signedIn = this.props.signInFacebook();
                                this.setState({ facebookSignedIn: signedIn });
                                if (signedIn) {
                                    this.setState({
                                        successBanner: "Youve Been Signed In"
                                    });
                                }
                            }}
                        >
                            Facebook
                        </Button> */}
                        {/* <Button
                            className="signInButtonGoogle"
                            size="small"
                            color="primary"
                            id="googleButton"
                            onClick={() => {
                                var signedIn = this.props.handleSignInGoogle();

                                if (signedIn) {
                                    this.setState({
                                        successBanner: "Youve Been Signed In"
                                    });
                                }
                            }}
                        >
                            Sign in with Google
                        </Button> */}
                    </div>
                </CardContent>
                <ForgotPasswordModal
                    open={this.state.open}
                    handleClose={this.handleClose}
                ></ForgotPasswordModal>
            </Card>
        );
    }
}

export default SignInCard;

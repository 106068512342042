const sortBy = [
    {
        prop: "year",
        direction: 1,
    },
    {
        prop: "month",
        direction: 1,
    },
    {
        prop: "day",
        direction: 1,
    },
];

export const DateSorter = (parties) => {
    return parties.sort((a, b) => {
        let aDateObject = a.dateObject;
        let bDateObject = b.dateObject;
        if (aDateObject < bDateObject) {
            return -1;
        }
        if (aDateObject > bDateObject) {
            return 1;
        }
        return 0;
        // let i = 0,
        //     result = 0;
        // while (i < sortBy.length && result === 0) {
        //     let aEl = a.partyDate[sortBy[i].prop] || false;
        //     let bElement = b.partyDate[sortBy[i].prop] || false;
        //     result =
        //         sortBy[i].direction *
        //         (aEl.toString() < bElement.toString()
        //             ? -1
        //             : aEl.toString() > bElement.toString()
        //             ? 1
        //             : 0);
        //     i++;
        // }
        // return result;
    });
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import { RWebShare } from "react-web-share";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "85%"
    },
    media: {
        height: 0,
        paddingTop: "56.25%" // 16:9
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    },
    avatar: {
        backgroundColor: red[500]
    },
    button: {
        backgroundColor: "#264D26",
        marginTop: 5,
        alignSelf: "center",
        width: "35%",
        textAlign: "center",
        alignContent: "center",
        alignItems: "center",
        color: "white",
        textDecoration: "none",
        marginBottom: "2.5%"
    },
    link: {
        alignSelf: "center",
        justifySelf: "center",
        textDecoration: "none"
    },
    hiddenButton: {
        alignContent: "flex-end",
        alignItems: "flex-end",
        alignSelf: "flex-end"
    }
}));

export default function RecipeReviewCard(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    let hourEnd = "";
    let minutesEnd = "";
    let ampmEnd = "";
    let timeEndString = null;
    if (props.party.timeSegments) {
        hourEnd = props.party.timeSegments.hourEnd;
        minutesEnd = props.party.timeSegments.minutesEnd;
        ampmEnd = props.party.timeSegments.ampmEnd;
        timeEndString = props.party.timeSegments.hourEnd
            ? " - " + hourEnd + ":" + minutesEnd + ampmEnd
            : null;
    }
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.root}>
            <CardContent>
                <CardActions
                    style={{
                        margin: 0,
                        padding: 0,
                        justifyContent: "flex-end"
                    }}
                >
                    <IconButton
                        aria-label="city homepage"
                        className={classes.hiddenButton}
                        onClick={() => {
                            if (props.userSettingsDoc) {
                                props.handleHiddenClick(props.party);
                            } else {
                                alert(
                                    "You need to be signed in to hide events"
                                );
                            }
                        }}
                    >
                        <i
                            class={
                                props.checkHiddenIncludes(props.party)
                                    ? "fas fa-eye"
                                    : "far fa-eye-slash"
                            }
                        ></i>
                    </IconButton>
                </CardActions>
                <Typography>{props.party.title}</Typography>
                <Typography variant="body2">
                    City: {props.party.cityName}
                    {props.party.type === "city" ? (
                        <i
                            style={{ marginLeft: ".5rem", color: "#555" }}
                            class="fas fa-users"
                        ></i>
                    ) : null}
                    {props.party.type === "business" ? (
                        <i
                            style={{ marginLeft: ".5rem", color: "#555" }}
                            class="fas fa-briefcase"
                        ></i>
                    ) : null}
                    {props.party.type === "school" ? (
                        <i
                            style={{ marginLeft: ".5rem", color: "#555" }}
                            class="fas fa-graduation-cap"
                        ></i>
                    ) : null}
                    <br></br>
                    Date: {props.party.partyDate.month}
                    {"/"}
                    {props.party.partyDate.day}
                    {"/"}
                    {props.party.partyDate.year}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {props.party.smallDescription}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton
                    aria-label="city homepage"
                    onClick={() => {
                        if (props.page === 5) {
                            return;
                        } else {
                            props.handlePartyCardCityButton(props.party);
                        }
                    }}
                >
                    <i class="fas fa-city"></i>
                </IconButton>

                <IconButton
                    aria-label="add to favorites"
                    onClick={() => {
                        if (props.userSettingsDoc) {
                            props.handleFavoriteClick(props.party);
                        } else {
                            alert(
                                "You need to be signed in to favorite events"
                            );
                        }
                    }}
                >
                    <i
                        class={
                            props.checkFavoritesIncludes(props.party)
                                ? "fas fa-star"
                                : "far fa-star"
                        }
                    ></i>
                </IconButton>
                <RWebShare
                    data={{
                        text: "Look at this cool event I found on Neighbored",
                        url:
                            "https://neighboredllcapp.com/ShareParty/" +
                            props.party.uid,
                        title: "Neighbored Event"
                    }}
                    sites={[
                        "facebook",
                        "twitter",
                        "whatsapp",
                        "reddit",
                        "linkedin",
                        "mail",
                        "copy"
                    ]}
                    onClick={() => console.log("shared successfully!")}
                >
                    <IconButton
                        aria-label="share party"
                        onClick={() => {
                            console.log("shareParty");
                        }}
                    >
                        <i class="fas fa-share-square"></i>
                    </IconButton>
                </RWebShare>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <i class="fas fa-angle-double-down"></i>
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="body2">
                        Time: {props.party.time}
                    </Typography>

                    <Typography variant="body2">
                        Address: {props.party.address}
                    </Typography>

                    <Typography variant="body2">
                        {props.party.fullDescription}
                    </Typography>
                </CardContent>
                {props.party.link === "" ||
                props.party.link === undefined ? null : (
                    <a
                        href={props.party.link}
                        target="_blank"
                        className={classes.link}
                    >
                        <Button primary className={classes.button}>
                            Event Link
                        </Button>
                    </a>
                )}
            </Collapse>
        </Card>
    );
}

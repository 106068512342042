import db from "../firebase";

export default function HandleFormSubmit(party) {
    db.collection("Parties")
        .add(party)
        .then((docRef) => {})
        .catch((error) => {
            console.error("Error adding document: ", error);
        });
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import RadiusSlider from "./radiusSlider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import NeighboredCircle from "../images/NeighboredLogoV2.png";
import MidHeadieUser from "./midHeadieUser";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    }
}));

const userSettingsStorage = JSON.parse(
    localStorage.getItem("userSettingsDoc")
) || { email: "", favorites: [], hidden: [], radius: 70 };

export default function SimpleExpansionPanel({
    signOut,
    userSettingsDoc,
    checkFavoritesIncludes,
    handlePartyCardCityButton,
    handleFavoriteClick,
    handleHiddenClick,
    checkHiddenIncludes,
    onSliderChange,
    radius
}) {
    const classes = useStyles();
    const userSettingsDocCorrect = userSettingsDoc
        ? userSettingsDoc
        : userSettingsStorage;
    return (
        <div
            className={classes.root}
            style={{ display: "flex", flexDirection: "row", height: "100%" }}
        >
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Card className="topAdminCard">
                    <CardContent
                        className="bottomSignCard"
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <CardMedia
                            component="img"
                            alt="NeighboredCircleLogoV2"
                            width="20px"
                            height="auto"
                            style={{
                                alignSelf: "center"
                            }}
                            className="signUpCardImage"
                            image={NeighboredCircle}
                            title="NeighboredCircleLogoV2"
                        />

                        <Typography variant="body2">
                            {userSettingsDocCorrect.email}
                        </Typography>

                        <Button
                            variant="contained"
                            color="primary"
                            href="#contained-buttons"
                            name="Sign Out"
                            id="signOutButton"
                            onClick={() => signOut()}
                        >
                            Sign Out
                        </Button>
                    </CardContent>
                </Card>
                <Card className="bottomAdminCard">
                    <CardContent
                        className="bottomSignCard"
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <RadiusSlider
                            radius={radius}
                            onSliderChange={onSliderChange}
                        ></RadiusSlider>
                    </CardContent>
                </Card>
            </div>

            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <MidHeadieUser
                    userSettingsDoc={userSettingsDocCorrect}
                    checkFavoritesIncludes={checkFavoritesIncludes}
                    checkHiddenIncludes={checkHiddenIncludes}
                    handlePartyCardCityButton={handlePartyCardCityButton}
                    handleFavoriteClick={handleFavoriteClick}
                    handleHiddenClick={handleHiddenClick}
                ></MidHeadieUser>
            </div>
        </div>
    );
}

import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
    useLocation,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import * as firebase from "firebase/app";
import "firebase/auth";
import BottomBuffer from "./components/bottomBuffer";
import NewDashboard from "./components/NewDashboard";
import db, { fire, analytics } from "./firebase";
import fillQueryList from "./TwoZeroFunctions/fillQueryList";
import fillPartyList from "./TwoZeroFunctions/fillPartyList";
import returnCityQueryNames from "./TwoZeroFunctions/returnCityQueryNames";
import { DateSorter } from "./TwoZeroFunctions/dateSorter";
import PartyCardList from "./components/partyCardList";
import CityCardList from "./components/cityCardList";
import SignInOrUpCard from "./components/signInOrUpCard";
import SignInCard from "./components/signInCard";
import SignUpCard from "./components/signUpCardFunctionalComponent";
import CityPage from "./components/cityPage";
import CreateAPartyForm from "./components/createAPartyFormFunctionComponent";
import SkeletonLoaderPartyCards from "./components/skeletonLoaderPartyCards";
import SkeletonLoaderCityCards from "./components/skeletonLoaderCityCards";
import CreateNewDbUser from "./TwoZeroFunctions/createNewDbUser";
import GetUserLogs from "./TwoZeroFunctions/getUserLogs";
import CreateNewDbUserLog from "./TwoZeroFunctions/createNewDbUserLog";
import UpdateUserLogs from "./TwoZeroFunctions/updateUserLogs";
import "./App.css";
import NoCitiesInRadius from "./components/noCitiesInRadius";
import NoPartiesCard from "./components/noPartiesCard";
import EditPartyForm from "./components/editPartyForm";
import NoLocationServices from "./components/noLocationServices";
import * as Sentry from "@sentry/react";
import SharePartyScreen from "./components/SharePartyScreen";
import UserScreen from "./components/userScreen";
import SmartBanner from "./components/smartBanner";

Sentry.init({
    dsn: "https://57b42e332edc4447a5c429bf8f11ff5f@o918562.ingest.sentry.io/5861891",
    enableInExpoDevelopment: true,
    debug: true,
});

var googleProvider = new firebase.auth.GoogleAuthProvider();
var facebookProvider = new firebase.auth.FacebookAuthProvider();

export default function App(props) {
    const [user, setUser] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [queryList, setQueryList] = useState([]);
    const [citiesInRadius, setCitiesInRadius] = useState([]);
    const [searchFilter, setSearchFilter] = useState("");
    const [partyList, setPartyList] = useState([]);
    const [partyTypeFilterO, setPartyTypeFilter0] = useState({
        city: true,
        school: true,
        business: true,
    });
    const [dayFilter, setDayFilter] = useState("0");
    const [monthFilter, setMonthFilter] = useState("0");
    const [hasLocationServices, setHasLocationServices] = useState(true);
    const [cityPageCity, setCityPageCity] = useState({});
    const [userSettingsDoc, setUserSettingsDoc] = useState(null);
    const [adminSettingsDoc, setAdminSettingsDoc] = useState({});
    const [adminPartyList, setAdminPartyList] = useState([]);
    const [editParty, setEditParty] = useState({
        title: "",
        partyDate: "date",
        address: "address",
        zipCode: "zipCode",
        time: "time",
        smallDescription: "smallDescription",
        fullDescription: "fullDescription",
    });
    const [radius, setRadius] = useState(0);
    const [showAllEvents, setShowAllEvents] = useState(false);

    const history = useHistory();
    const locationRouter = useLocation();
    const [isMobile, setIsMobile] = useState(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    );

    const success = (pos) => {
        var crd = pos.coords;
        returnCityQueryNames(crd.latitude, crd.longitude);
        let filledQueryList = fillQueryList(crd.latitude, crd.longitude);
        setQueryList(filledQueryList);
        let partyList = fillPartyList(filledQueryList);
        partyList.then((results) => {
            DateSorter(results);
            setPartyList([...results]);
            setLoaded(true);
        });

        setCitiesInRadius(filledQueryList);
    };

    const errorLocation = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    };

    const changeScreen = (screen) => {
        history.push("/" + screen);
        window.scrollTo(0, 0);
    };

    const changeScreenShareParty = (screen) => {
        history.replace("/" + screen);
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        analytics.logEvent("session_start", {
            name: "session_start",
            screen: "main",
            purpose: "Opened the PWA",
        });
        try {
            navigator.permissions
                .query({ name: "geolocation" })
                .then((permissionStatus) => {
                    getLocation(permissionStatus.state);

                    permissionStatus.onchange = function () {
                        console.log(
                            "geolocation permission status has changed to "
                        );
                    };
                });
        } catch (error) {
            console.log("navigator permission error", error);
        }
        if (navigator.geolocation) {
            console.log("has location");
            navigator.geolocation.getCurrentPosition(success, errorLocation);
        } else {
            console.log("needs location");
            setHasLocationServices(false);
        }

        authListener();
    }, []);

    useEffect(() => {
        localStorage.setItem(
            "userSettingsDoc",
            JSON.stringify(userSettingsDoc)
        );
    }, [userSettingsDoc]);

    const authListener = () => {
        fire.auth().onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
                db.collection("Users")
                    .doc(user.uid)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            var docData = doc.data();
                            setUserSettingsDoc(docData);
                            localStorage.setItem("uid", docData.uid);
                            setRadius(docData.radius);
                            if (docData.isAdmin) {
                                db.collection("Admins")
                                    .where("uid", "==", docData.uid)
                                    .get()
                                    .then((Admin) => {
                                        Admin.forEach((snap) => {
                                            console.log(
                                                "ADMINSETDOC",
                                                snap.data()
                                            );
                                            setAdminSettingsDoc(snap.data());
                                            var adminParties = [];
                                            snap.data().cityNames.forEach(
                                                (cityName) => {
                                                    db.collection("Parties")
                                                        .where(
                                                            "cityName",
                                                            "==",
                                                            cityName
                                                        )
                                                        .get()
                                                        .then((parties) => {
                                                            parties.forEach(
                                                                (snap) =>
                                                                    adminParties.push(
                                                                        {
                                                                            ...snap.data(),
                                                                            uid: snap.id,
                                                                        }
                                                                    )
                                                            );
                                                        });
                                                }
                                            );
                                            setAdminPartyList(adminParties);
                                        });
                                    });
                                // .then(() => changeScreen("userScreen"));
                            } else {
                                // changeScreen("userScreen");
                            }
                            let userLogs = GetUserLogs(docData.uid);
                            userLogs.then((userLogs) => {
                                if (userLogs) {
                                    let time = new Date();
                                    let action = { type: "signIn", date: time };
                                    UpdateUserLogs(action, userLogs);
                                } else {
                                    let userLogs = CreateNewDbUserLog(
                                        docData.uid
                                    );
                                }
                            });
                            // RemoveExpiredEvents(docData, dispatch);
                        } else {
                            console.log("No Such Document");
                        }
                    })
                    .catch(function (error) {
                        Sentry.captureException(error);
                    });
            } else {
                setUser(null);
            }
        });
    };

    const getLocation = (permissionStatus) => {
        if (permissionStatus === "denied") {
            setHasLocationServices(false);
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                const cityQueryNames = returnCityQueryNames(
                    position.coords.latitude,
                    position.coords.longitude
                );
                let filledQueryList = fillQueryList(
                    position.coords.latitude,
                    position.coords.longitude
                );
                setQueryList(filledQueryList);
                let partyList = fillPartyList(filledQueryList);
                partyList.then((results) => {
                    DateSorter(results);
                    setPartyList([...results]);
                    setLoaded(true);
                });

                setCitiesInRadius(filledQueryList);
            });
        }
    };

    const handleFavoriteClick = (party) => {
        if (userSettingsDoc) {
            var hasFavorite = false;
            var favorites = userSettingsDoc.favorites;
            favorites.forEach((userFav) => {
                if (userFav.uid === party.uid) {
                    hasFavorite = true;
                }
            });

            if (hasFavorite) {
                var newFavorites = favorites.filter((userFav) => {
                    return userFav.uid !== party.uid;
                });
                let sortedFavorites = DateSorter(newFavorites);
                db.collection("Users")
                    .doc(user.uid)
                    .update({ favorites: newFavorites })
                    .then(() => {
                        setUserSettingsDoc({
                            ...userSettingsDoc,
                            favorites: sortedFavorites,
                        });
                    })
                    .catch(function (error) {
                        Sentry.captureException(error);
                    });
                console.log("Favorite is already in the array");
            }

            if (!hasFavorite) {
                newFavorites = [...favorites, party];
                let sortedFavorites = DateSorter(newFavorites);
                db.collection("Users")
                    .doc(user.uid)
                    .update({ favorites: newFavorites })
                    .then(() => {
                        setUserSettingsDoc({
                            ...userSettingsDoc,
                            favorites: sortedFavorites,
                        });
                    })
                    .catch(function (error) {
                        Sentry.captureException(error);
                    });
            }
        }
    };

    const handleHiddenClick = (party) => {
        if (userSettingsDoc) {
            var hasHidden = false;
            var hidden = userSettingsDoc.hidden;
            hidden.forEach((userHide) => {
                if (userHide.uid === party.uid) {
                    hasHidden = true;
                }
            });

            if (hasHidden) {
                var newHidden = hidden.filter((userHide) => {
                    return userHide.uid !== party.uid;
                });
                let sortedHidden = DateSorter(newHidden);
                db.collection("Users")
                    .doc(user.uid)
                    .update({ hidden: newHidden })
                    .then(() => {
                        setUserSettingsDoc({
                            ...userSettingsDoc,
                            hidden: sortedHidden,
                        });
                    })
                    .catch(function (error) {
                        Sentry.captureException(error);
                    });
            }

            if (!hasHidden) {
                newHidden = [...hidden, party];
                let sortedHidden = DateSorter(newHidden);
                db.collection("Users")
                    .doc(user.uid)
                    .update({ hidden: newHidden })
                    .then(() => {
                        setUserSettingsDoc({
                            ...userSettingsDoc,
                            hidden: sortedHidden,
                        });
                    })
                    .catch(function (error) {
                        Sentry.captureException(error);
                    });
                console.log("Favorite Was just added to the array");
            }
        }
    };

    const checkFavoritesIncludes = (party) => {
        if (userSettingsDoc) {
            const favorites = userSettingsDoc.favorites;
            var includesParty = favorites.some((fav) => {
                return fav.uid === party.uid;
            });
            return includesParty;
        }
    };

    const checkHiddenIncludes = (party) => {
        if (userSettingsDoc) {
            const hidden = userSettingsDoc.hidden;
            var includesParty = hidden.some((hiddenEvent) => {
                return hiddenEvent.uid === party.uid;
            });
            return includesParty;
        }
    };

    const onSliderChange = (event, value) => {
        setRadius(value);
        var dbUser = db.collection("Users").doc(user.uid);

        dbUser
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    console.log("doc exits");
                } else {
                    console.log("No Such Document");
                }
            })
            .catch(function (error) {
                Sentry.captureException(error);
            });

        dbUser.update({ radius: radius });
    };

    const handleGoToApprovalEditCard = (party) => {
        setEditParty(party);
        localStorage.setItem("editParty", JSON.stringify(party));
        changeScreen("editEvent");
    };

    const handleApprovalCheckClick = (party) => {
        var newParty = { ...party, approvalPhase: "3", approved: true };
        db.collection("Parties")
            .doc(party.uid)
            .update({ ...newParty })
            .then(() => {
                var filteredPartyList = adminPartyList.filter((partyKnot) => {
                    return partyKnot.uid !== party.uid;
                });
                var newPartyList = [...filteredPartyList, newParty];
                let sortedNewPartyList = DateSorter(newPartyList);
                setAdminPartyList(sortedNewPartyList);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    const handleApprovalXClick = (party) => {
        var newParty = { ...party, approvalPhase: "2", approved: false };
        db.collection("Parties")
            .doc(party.uid)
            .update({ ...newParty })
            .then(() => {
                var filteredPartyList = adminPartyList.filter((partyKnot) => {
                    return partyKnot.uid !== party.uid;
                });
                var newPartyList = [...filteredPartyList, newParty];
                let sortedNewPartyList = DateSorter(newPartyList);
                setAdminPartyList(sortedNewPartyList);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    const signIn = (email, password) => {
        fire.auth()
            .signInWithEmailAndPassword(email, password)
            .then((u) => {
                console.log("succesfully Logged In");
                if (u.additionalUserInfo.isNewUser) {
                    //create User
                } else {
                    //do something with user
                }
                return true;
            })
            .catch((err) => {
                console.log("error:" + err.toString());
                return false;
            });

        changeScreen("userScreen");
    };

    const handleSignUpEmail = (email, password, zipCode) => {
        fire.auth()
            .createUserWithEmailAndPassword(email, password)
            .then((u) => {
                let userSettingsDoc = CreateNewDbUser(u.user, zipCode);
                setUserSettingsDoc(userSettingsDoc);
                return true;
            })
            .catch((err) => {
                console.log("error:" + err.toString());
                return false;
            });
        changeScreen("userScreen");
    };

    const signOut = () => {
        fire.auth().signOut();
        setUserSettingsDoc(null);
        localStorage.setItem("uid", null);
        setAdminSettingsDoc(null);
        setAdminPartyList(null);
        changeScreen("signIn");
    };

    const handleSignInGoogle = () => {
        console.log("GoogleSignin");
        firebase
            .auth()
            .signInWithPopup(googleProvider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                return true;
                // ...
            })
            .catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
                console.log("signInGoogleError");
                return false;
            });
        changeScreen("userScreen");
    };

    const handleSignUpGoogle = (zipCode) => {
        firebase
            .auth()
            .signInWithPopup(googleProvider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                // var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                CreateNewDbUser(user, zipCode);
                // ...
                return true;
            })
            .catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
                console.log("signUpGoogleError");
                return false;
            });
        changeScreen("userScreen");
    };

    const updateAdminPartyList = (bundledParty) => {
        let removedPartyAdminList = adminPartyList.filter(
            (party) => party.uid !== bundledParty.uid
        );
        let newAdminPartyList = [...removedPartyAdminList, bundledParty];
        let sortedNewAdminPartyList = DateSorter(newAdminPartyList);
        setAdminPartyList(sortedNewAdminPartyList);
    };

    const deleteFromAdminPartyList = (deleteParty) => {
        let removedPartyAdminList = adminPartyList.filter(
            (party) => party.uid !== deleteParty.uid
        );
        let sortedRemovedPartyAdminList = DateSorter(removedPartyAdminList);
        setAdminPartyList(sortedRemovedPartyAdminList);
    };

    const clickSearchResultCity = (name) => {
        const city = queryList.filter((city) => city.name === name);
        setCityPageCity(city[0]);
        changeScreen("cityPage");
        localStorage.setItem("cityPageCity", JSON.stringify(city[0]));
    };

    const handleGoToCityPage = (event, city) => {
        setCityPageCity(city);
        localStorage.setItem("cityPageCity", JSON.stringify(city));
        changeScreen("cityPage");
    };

    const handlePartyCardCityButton = (party) => {
        var partyZipCode = party.zipCode;
        for (let city of citiesInRadius) {
            if (city.zipCode === partyZipCode) {
                setCityPageCity(city);
                localStorage.setItem("cityPageCity", JSON.stringify(city));
                changeScreen("cityPage");
            }
        }
    };

    const handleGoToCreateAnEvent = (event) => {
        changeScreen("createAnEvent");
    };

    const getCitiesFromFirestore = async () => {
        let firestoreCities = [];
        // var chunkedQueryList = queryList.chunk_inefficient(10);
        var chunkedQueryList = chunkedQueryList;

        for (let index = 0; index < chunkedQueryList.length; index++) {
            const chunk = chunkedQueryList[index];

            let snapshot = await db
                .collection("Cities")
                .where(
                    "name",
                    "in",
                    chunk.map((city) => city.name)
                )
                .get();

            snapshot.forEach((snap) =>
                firestoreCities.push({ ...snap.data(), uid: snap.id })
            );
        }
        setCitiesInRadius(firestoreCities);
        setLoaded(true);
    };

    return (
        <div className="App">
            {isMobile ? <SmartBanner></SmartBanner> : null}
            <NewDashboard
                changeScreen={changeScreen}
                queryList={queryList}
                clickSearchResultCity={clickSearchResultCity}
            ></NewDashboard>
            <TransitionGroup>
                <CSSTransition
                    timeout={125}
                    classNames="fade"
                    key={locationRouter.key}
                >
                    <Switch location={locationRouter}>
                        <Route exact path="/">
                            {hasLocationServices ? null : (
                                <NoLocationServices></NoLocationServices>
                            )}
                            {partyList.length > 0 ? (
                                <PartyCardList
                                    partyList={partyList}
                                    partyTypeFilterO={partyTypeFilterO}
                                    searchFilter={searchFilter}
                                    dayFilter={dayFilter}
                                    latitude={latitude}
                                    longitude={longitude}
                                    queryList={queryList}
                                    changeScreen={changeScreen}
                                    monthFilter={monthFilter}
                                    handlePartyCardCityButton={
                                        handlePartyCardCityButton
                                    }
                                    checkFavoritesIncludes={
                                        checkFavoritesIncludes
                                    }
                                    checkHiddenIncludes={checkHiddenIncludes}
                                    userSettingsDoc={userSettingsDoc}
                                    handleFavoriteClick={handleFavoriteClick}
                                    handleHiddenClick={handleHiddenClick}
                                ></PartyCardList>
                            ) : loaded ? (
                                <NoPartiesCard></NoPartiesCard>
                            ) : null}
                            {!loaded && hasLocationServices && (
                                <SkeletonLoaderPartyCards></SkeletonLoaderPartyCards>
                            )}
                            <BottomBuffer></BottomBuffer>
                        </Route>

                        <Route path="/citiesInRadius">
                            {citiesInRadius.length > 0 ? (
                                <CityCardList
                                    citiesInRadius={citiesInRadius}
                                    handleGoToCityPage={handleGoToCityPage}
                                    latitude={latitude}
                                    longitude={longitude}
                                    userSettingsDoc={userSettingsDoc}
                                ></CityCardList>
                            ) : loaded ? (
                                <NoCitiesInRadius></NoCitiesInRadius>
                            ) : null}
                            {!loaded && (
                                <SkeletonLoaderCityCards></SkeletonLoaderCityCards>
                            )}
                            <BottomBuffer></BottomBuffer>
                        </Route>

                        <Route path="/userScreen">
                            {userSettingsDoc ? (
                                <UserScreen
                                    signOut={signOut}
                                    adminPartyList={adminPartyList}
                                    handleGoToApprovalEditCard={
                                        handleGoToApprovalEditCard
                                    }
                                    handleApprovalCheckClick={
                                        handleApprovalCheckClick
                                    }
                                    handleApprovalXClick={handleApprovalXClick}
                                    deleteFromAdminPartyList={
                                        deleteFromAdminPartyList
                                    }
                                    checkFavoritesIncludes={
                                        checkFavoritesIncludes
                                    }
                                    checkHiddenIncludes={checkHiddenIncludes}
                                    handlePartyCardCityButton={
                                        handlePartyCardCityButton
                                    }
                                    radius={radius}
                                    onSliderChange={onSliderChange}
                                    handleFavoriteClick={handleFavoriteClick}
                                    handleHiddenClick={handleHiddenClick}
                                    userSettingsDoc={userSettingsDoc}
                                ></UserScreen>
                            ) : (
                                <SignInOrUpCard
                                    changeScreen={changeScreen}
                                ></SignInOrUpCard>
                            )}
                            <BottomBuffer></BottomBuffer>
                        </Route>

                        <Route path="/signIn">
                            <SignInCard
                                signIn={signIn}
                                handleSignInGoogle={handleSignInGoogle}
                            ></SignInCard>
                            <BottomBuffer></BottomBuffer>
                        </Route>

                        <Route path="/signUp">
                            <SignUpCard
                                handleSignUpEmail={handleSignUpEmail}
                                handleSignUpGoogle={handleSignUpGoogle}
                            ></SignUpCard>
                            <BottomBuffer></BottomBuffer>
                        </Route>

                        <Route path="/cityPage">
                            <CityPage
                                city={cityPageCity}
                                partyList={partyList}
                                handlePartyCardCityButton={
                                    handlePartyCardCityButton
                                }
                                handleGoToCreateAnEvent={
                                    handleGoToCreateAnEvent
                                }
                                handleFavoriteClick={handleFavoriteClick}
                                handleHiddenClick={handleHiddenClick}
                                userSettingsDoc={userSettingsDoc}
                                checkFavoritesIncludes={checkFavoritesIncludes}
                                checkHiddenIncludes={checkHiddenIncludes}
                            ></CityPage>
                            <BottomBuffer></BottomBuffer>
                        </Route>

                        <Route path="/createAnEvent">
                            <CreateAPartyForm
                                city={cityPageCity}
                            ></CreateAPartyForm>
                            <BottomBuffer></BottomBuffer>
                        </Route>

                        <Route path="/editEvent">
                            <EditPartyForm
                                party={editParty}
                                changeScreen={changeScreen}
                                updateAdminPartyList={updateAdminPartyList}
                            ></EditPartyForm>
                        </Route>
                        <Route path="/ShareParty/:uid">
                            <SharePartyScreen
                                handlePartyCardCityButton={
                                    handlePartyCardCityButton
                                }
                                checkFavoritesIncludes={checkFavoritesIncludes}
                                checkHiddenIncludes={checkHiddenIncludes}
                                userSettingsDoc={userSettingsDoc}
                                handleFavoriteClick={handleFavoriteClick}
                                handleHiddenClick={handleHiddenClick}
                            ></SharePartyScreen>
                        </Route>
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
}

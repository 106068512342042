import React, { useState } from "react";
import TextField from "./textField";

const DateFields = ({
    handleDateChange,
    index,
    inputField,
    setErrorPostMessage,
    setSuccessfulPostMessage
}) => {
    const [dayErrorMessage, setDayErrorMessage] = useState("");
    const [monthErrorMessage, setMonthErrorMessage] = useState("");
    const [yearErrorMessage, setYearErrorMessage] = useState("");

    const isnum = (value) => {
        let isNum = /^\d+$/.test(value);
        return isNum;
    };

    const validateDomesticPartyDay = (day) => {
        if (day.length === 2 && isnum(day)) {
            setDayErrorMessage("");
            setErrorPostMessage("");
        } else {
            setDayErrorMessage("Format the day as DD");
            setErrorPostMessage("You need to fix your Day");
            setSuccessfulPostMessage("");
        }
    };
    const validateDomesticPartyMonth = (month) => {
        if (month.length === 2 && isnum(month)) {
            setMonthErrorMessage("");
            setErrorPostMessage("");
        } else {
            setMonthErrorMessage("Format the month as MM");
            setErrorPostMessage("You need to fix your Month");
            setSuccessfulPostMessage("");
        }
    };
    const validateDomesticPartyYear = (year) => {
        if (year.length === 4 && isnum(year)) {
            setYearErrorMessage("");
            setErrorPostMessage("");
        } else {
            setYearErrorMessage("Format the year as YYYY");
            setErrorPostMessage("You need to fix your Year");
            setSuccessfulPostMessage("");
        }
    };

    return (
        <div>
            <div key={index} className="dateFields">
                <TextField
                    id="outlined-basic month"
                    label="MM*"
                    variant="outlined"
                    onChange={(event) => {
                        handleDateChange(event, index);
                        validateDomesticPartyMonth(event.target.value);
                    }}
                    name="month"
                    value={inputField.month}
                />
                <TextField
                    id="outlined-basic day"
                    label="DD*"
                    variant="outlined"
                    onChange={(event) => {
                        handleDateChange(event, index);
                        validateDomesticPartyDay(event.target.value);
                    }}
                    name="day"
                    value={inputField.day}
                />
                <TextField
                    id="outlined-basic year"
                    label="YYYY*"
                    variant="outlined"
                    onChange={(event) => {
                        handleDateChange(event, index);
                        validateDomesticPartyYear(event.target.value);
                    }}
                    name="year"
                    value={inputField.year}
                />
            </div>
            <div className="dateFieldErrorMessages">
                <h5
                    className={
                        monthErrorMessage === "" ? "hidden" : "errorMessage"
                    }
                >
                    {monthErrorMessage}
                </h5>
                <h5
                    className={
                        dayErrorMessage === "" ? "hidden" : "errorMessage"
                    }
                >
                    {dayErrorMessage}
                </h5>
                <h5
                    className={
                        yearErrorMessage === "" ? "hidden" : "errorMessage"
                    }
                >
                    {yearErrorMessage}
                </h5>
            </div>
        </div>
    );
};

export default DateFields;
